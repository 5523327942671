import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ExamWizard } from './exam-wizard.service';
import { ExaminationConfiguration, TaskResult } from './models';

@Component({
  selector: 'ac-exam',
  template: `
    <ng-container #taskHost></ng-container>
    <ng-container *ngIf="currentTask$ | async as currentTask">
      <ac-task-summary [state]="currentTask"></ac-task-summary>
      <div class="task-navigation">
        <button
          wvButton
          *ngIf="currentTask.isComplete"
          (click)="activateNextTask()"
          appearance="secondary"
          data-test="button-next-task"
          data-testid="button-next-task"
        >
          {{ 'exam.nextButton' | transloco }}
        </button>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .task-navigation {
        display: flex;
        justify-content: flex-start;
      }

      button:focus-visible {
        outline: var(--color-secondary) auto 2px;
      }
    `
  ],
  providers: [ExamWizard]
})
export class ExamComponent implements OnInit {
  @ViewChild('taskHost', { static: true, read: ViewContainerRef }) taskHost: ViewContainerRef;

  @Input() configuration: ExaminationConfiguration;
  @Output() complete = new EventEmitter<{ correctAnswers: number }>();

  currentTask$: Observable<TaskResult> = this.examWizard.currentTask();

  constructor(private examWizard: ExamWizard) {}

  ngOnInit(): void {
    this.examWizard.useHost(this.taskHost).start(this.configuration);

    this.examWizard
      .examComplete()
      .pipe(tap(correctAnswers => this.complete.emit({ correctAnswers })))
      .subscribe();
  }

  activateNextTask() {
    this.examWizard.proceedWithNextTask();
  }
}
