import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DefaultDataServiceConfig, EntityDataModule, HttpUrlGenerator } from '@ngrx/data';
import { TranslateModule } from '../translate.module';
import { CmsRoutingModule } from './cms-routing.module';
import { CmsComponent } from './cms.component';
import { defaultDataServiceConfig, entityConfig } from './ngrx-data';
import { FeedbackModule } from '../feedback/feedback.module';
import { LayoutsModule } from '../layouts/layouts.module';
import { HasRoleDirective } from '../identity/has-role.directive';
import { CmsHttpUrlGenerator } from './ngrx-data';
import { StoreModule } from '@ngrx/store';
import { courseFeatureKey } from './store/course.selectors';
import { courseReducer } from './store/course.reducer';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [CmsComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature(courseFeatureKey, courseReducer),
    EntityDataModule.forRoot(entityConfig),
    MatIconModule,
    CmsRoutingModule,
    TranslateModule,
    FeedbackModule,
    LayoutsModule,
    HasRoleDirective,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatSnackBarModule
  ],
  providers: [
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
    { provide: HttpUrlGenerator, useClass: CmsHttpUrlGenerator }
  ]
})
export class CmsModule {}
