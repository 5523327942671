import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition
} from '@angular/cdk/overlay';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'ac-help-button',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, CdkConnectedOverlay, CdkOverlayOrigin],
  templateUrl: './help-button.component.html',
  styleUrl: './help-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpButtonComponent {
  #changeDetector = inject(ChangeDetectorRef);
  isOverlayOpen = signal(false);
  closeButtonPosition = signal<'start' | 'end' | null>(null);
  overlayPositions: ConnectedPosition[] = [
    { originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top' },
    { originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'top' },
    { originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
    { originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'top' }
  ];

  openOverlay() {
    this.isOverlayOpen.set(true);
  }

  closeOverlay() {
    this.isOverlayOpen.set(false);
  }

  updateCloseButtonPosition($event: ConnectedOverlayPositionChange) {
    if ($event.connectionPair.originX === 'center') {
      return;
    }

    this.closeButtonPosition.set($event.connectionPair.originX);

    this.#changeDetector.detectChanges(); //Otherwise class binding in template doesn't update
  }
}
