import { UserDto } from '@ac/models';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { IdentityService } from '../identity.service';
import { UserRolesService } from '../user-roles.service';
import { SelectOptionsService } from '@ac/colibri';
import { UserManagementService } from '../user-management.service';

@Component({
  selector: 'app-user-management-overview',
  templateUrl: './user-management-overview.component.html',
  styleUrls: ['./user-management-overview.component.scss'],
  providers: [UserRolesService]
})
export class UserManagementOverviewComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<UserDto> = new MatTableDataSource<UserDto>();
  displayedColumns: string[] = ['firstName', 'lastName', 'email', 'department', 'assignedRoles', 'status', 'actions'];

  managedRoles: string[] = [];
  departments = this.selectOptions.departments();
  roleIsUpdating: boolean;
  departmentIsUpdating: boolean;

  constructor(
    private identityService: IdentityService,
    private userRolesService: UserRolesService,
    private selectOptions: SelectOptionsService,
    private userManagementService: UserManagementService
  ) {}

  ngOnInit(): void {
    this.loadUsers();
    this.loadRolesAllowedToChange();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  resendInvitation(user: UserDto) {
    this.identityService.resendInvitation(user.id).subscribe();
  }

  changeRole(user: UserDto, roles: string[]) {
    this.roleIsUpdating = true;

    this.userRolesService
      .update(user, roles, this.managedRoles)
      .pipe(
        switchMap(() => this.identityService.getUsers()),
        tap(users => (this.dataSource.data = users)),
        finalize(() => (this.roleIsUpdating = false))
      )
      .subscribe();
  }

  changeDepartment(user: UserDto, department: string) {
    this.departmentIsUpdating = true;

    this.userManagementService
      .updateDepartment(user.id, department)
      .pipe(finalize(() => (this.departmentIsUpdating = false)))
      .subscribe();
  }

  deleteInvitation(user: UserDto) {
    this.identityService.deleteInvitation(user.id).subscribe();
  }

  deleteUser(user: UserDto) {
    this.identityService.deleteUser(user.id).subscribe();
  }

  private loadUsers() {
    this.identityService
      .getUsers()
      .pipe(tap(users => (this.dataSource.data = users)))
      .subscribe();
  }

  private loadRolesAllowedToChange() {
    this.userRolesService
      .loadManagedRoles(this.identityService.getUserId())
      .pipe(tap(roles => (this.managedRoles = roles)))
      .subscribe();
  }
}
