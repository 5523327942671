import { ColibriModule } from '@ac/colibri';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { FeedbackModule } from '../feedback/feedback.module';
import { NavigationModule } from '../navigation/navigation.module';
import { TranslateModule } from '../translate.module';
import { BackLayoutComponent } from './back-layout/back-layout.component';
import { InfoVideoLayoutComponent } from './info-video-layout/info-video-layout.component';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { NavigationLayoutComponent } from './navigation-layout/navigation-layout.component';
import { PlainLayoutComponent } from './plain-layout/plain-layout.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    NavigationLayoutComponent,
    LoadingDialogComponent,
    PlainLayoutComponent,
    BackLayoutComponent,
    InfoVideoLayoutComponent
  ],
  imports: [
    CommonModule,
    NavigationModule,
    RouterModule,
    MatIconModule,
    ColibriModule,
    ReactiveFormsModule,
    TranslateModule,
    FeedbackModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule,
    MatProgressSpinnerModule
  ],
  exports: [
    LoadingDialogComponent,
    PlainLayoutComponent,
    BackLayoutComponent,
    InfoVideoLayoutComponent,
    NavigationLayoutComponent
  ]
})
export class LayoutsModule {}
