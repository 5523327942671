import { ColibriModule } from '@ac/colibri';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { LayoutsModule } from '../layouts/layouts.module';
import { TranslateModule } from '../translate.module';
import { PasswordCheckComponent } from './password-check/password-check.component';
import { ToolkitComponent } from './toolkit/toolkit.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [PasswordCheckComponent, ToolkitComponent],
  imports: [
    CommonModule,
    ColibriModule,
    MatIconModule,
    ReactiveFormsModule,
    LayoutsModule,
    TranslateModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule
  ],
  exports: [PasswordCheckComponent, ToolkitComponent]
})
export class ToolkitModule {}
