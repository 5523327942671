import { ExaminationTask } from '@ac/exam';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ac-task-header',
  template: `
    <section class="exam__typography">
      <h3 data-test="title-task">{{ configuration.heading }}</h3>
      <img
        *ngIf="configuration.imageUrl"
        [src]="configuration.imageUrl"
        [attr.alt]="'Bild fuer Task: ' + configuration.heading"
      />
      <p [innerHTML]="configuration.description | safe"></p>
    </section>
  `,
  styleUrl: './task-header.component.scss'
})
export class TaskHeaderComponent {
  @Input() configuration: ExaminationTask;
}
