<app-back-layout
  [backUrl]="urlBuilder.goToHome()"
  [title]="'navigation.menuItem.phishingSimulations' | transloco"
>
  <div class="statistics">
    <nav
      mat-tab-nav-bar
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      [tabPanel]="tabPanel"
      color="accent"
    >
      <a
        mat-tab-link
        [routerLink]="[urlBuilder.goToPhishingMailsUser()]"
        [active]="activePath().startsWith(urlBuilder.goToPhishingMailsUser())"
      >
        {{ 'phishingSimulations.statistic.user.title' | transloco }}
      </a>
      <a
        mat-tab-link
        *ngIf="identityService.hasRole('Cyber-Verantwortlicher')"
        [routerLink]="[urlBuilder.goToPhishingMailsCompany()]"
        [active]="activePath().startsWith(urlBuilder.goToPhishingMailsCompany())"
      >
        {{ 'phishingSimulations.statistic.company.title' | transloco }}
      </a>
    </nav>
    <mat-tab-nav-panel #tabPanel>
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </div>
</app-back-layout>
