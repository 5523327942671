<button
  #trigger="cdkOverlayOrigin"
  (click)="openOverlay()"
  aria-label="Help Button"
  cdkOverlayOrigin
  mat-icon-button
>
  <mat-icon>help</mat-icon>
</button>

<ng-template
  (backdropClick)="closeOverlay()"
  (positionChange)="updateCloseButtonPosition($event)"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOffsetX]="-10"
  [cdkConnectedOverlayOffsetY]="30"
  [cdkConnectedOverlayOpen]="isOverlayOpen()"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayPositions]="overlayPositions"
  cdkConnectedOverlay
>
  <div class="max-w-[60ch] border-1 border-primary drop-shadow-xl bg-surface p-4 text-sm relative">
    <ng-content></ng-content>
    <button
      (click)="closeOverlay()"
      [class.close-end]="closeButtonPosition() === 'end'"
      [class.close-start]="closeButtonPosition() === 'start'"
      aria-label="Close button"
      class="!bg-secondary !text-on-secondary !absolute top-0 -translate-y-1/2 !w-7 !h-7 !leading-7"
      mat-icon-button
    >
      <mat-icon class="!w-5 !h-5 !leading-5 !text-[1.25rem] -top-1.5 -left-1.5">close</mat-icon>
    </button>
  </div>
</ng-template>
