import { Component, effect, inject, input } from '@angular/core';
import { ChartData, ChartOptions, ChartType, Tooltip } from 'chart.js';
import { DefaultChartOptionsService } from '../default-chart-options.service';

@Component({
  selector: 'app-phishing-mails-statistic-total-chart',
  templateUrl: './phishing-mails-statistic-total-chart.component.html',
  styleUrls: ['./phishing-mails-statistic-total-chart.component.scss']
})
export class PhishingMailsStatisticTotalChartComponent {
  mailsClicked = input.required<number>();
  mailsNotClicked = input.required<number>();
  pieChartOptions: ChartOptions<'pie'> = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2,
    plugins: { legend: { position: 'right' } }
  };
  pieChartData: ChartData<'pie', number[]> = { datasets: [], labels: [] };
  pieChartType: ChartType = 'pie';
  pieChartLegend: boolean;
  pieChartPlugins = [Tooltip];

  #defaultChartOptions = inject(DefaultChartOptionsService);

  constructor() {
    effect(() => {
      if (this.mailsClicked() === 0 && this.mailsNotClicked() == 0) {
        this.pieChartData.datasets = [
          {
            data: [1],
            ...this.#defaultChartOptions.getDefaultColors()
          }
        ];
        this.pieChartLegend = false;
        this.pieChartData.labels = [];
        this.pieChartPlugins = [];
      } else {
        this.pieChartData.datasets = [
          {
            data: [this.mailsClicked(), this.mailsNotClicked()],
            ...this.#defaultChartOptions.getDefaultColors()
          }
        ];

        this.pieChartLegend = true;
        this.pieChartData.labels = [['Geklickt'], ['Nicht geklickt']];
      }
    });
  }
}
