<app-back-layout
  [align]="false"
  [backUrl]="urlBuilder.goToHome()"
  [title]="'navigation.menuItem.help' | transloco"
>
  <mat-tab-group
    [disableRipple]="true"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    [dynamicHeight]="true"
    class="mat-x-tab-group-navigation"
    color="accent"
  >
    <mat-tab [label]="'help.tabLabel.videos' | transloco">
      <app-help-videos></app-help-videos>
    </mat-tab>
    <mat-tab [label]="'help.tabLabel.documents' | transloco">
      <app-help-documents></app-help-documents>
    </mat-tab>
  </mat-tab-group>
</app-back-layout>
