import { MultipleChoiceTask, TaskCompletion, TaskComponent, TaskOption } from '@ac/exam';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'ac-multiple-choice',
  templateUrl: 'multiple-choice.component.html',
  styleUrls: ['multiple-choice.component.scss']
})
export class MultipleChoiceComponent implements TaskComponent<MultipleChoiceTask> {
  configuration: MultipleChoiceTask;
  formSubmitted = false;
  private solution: TaskOption[] = [];
  private complete$$ = new Subject<TaskCompletion>();

  complete = () => this.complete$$.asObservable();

  prepare(configuration: MultipleChoiceTask): void {
    this.configuration = configuration;
  }

  submitAnswer() {
    this.formSubmitted = true;

    if (this.isCorrect(this.solution)) {
      this.complete$$.next({ isCorrect: true });
    } else {
      this.complete$$.next({ isCorrect: false });
    }
  }

  toggleAnswer(option: TaskOption, isChecked: boolean) {
    if (isChecked) {
      this.solution.push(option);
    } else {
      this.solution = this.solution.filter(entry => entry.label !== option.label);
    }
  }

  private isCorrect(solution: TaskOption[]): boolean {
    return (
      solution.length === this.configuration.solution.length &&
      solution.every(selectedOption =>
        this.configuration.solution.some(solutionOption => solutionOption.label === selectedOption.label)
      )
    );
  }
}
