import { UserLoginCommand } from '@ac/models';
import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UrlBuilder } from '../../url-builder.service';
import { NoAccessDialogComponent } from '../no-access-dialog/no-access-dialog.component';
import { CustomValidators } from '../custom.validator';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-login-form',
  templateUrl: './user-login-form.component.html',
  styleUrls: ['./user-login-form.component.scss']
})
export class UserLoginFormComponent {
  @Output() submitForm = new EventEmitter<UserLoginCommand>();
  userLoginForm: UntypedFormGroup;
  passwordHide = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public urlBuilder: UrlBuilder,
    private dialog: MatDialog
  ) {
    this.userLoginForm = this.formBuilder.group({
      email: ['', [CustomValidators.email, CustomValidators.username, Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  onSubmit(userData: UntypedFormGroup, event: Event): void {
    event.preventDefault();

    if (userData.invalid) {
      return;
    }

    const dto: UserLoginCommand = {
      email: userData.get('email').value,
      password: userData.get('password').value
    };

    this.submitForm.emit(dto);
  }

  openDialog() {
    this.dialog.open(NoAccessDialogComponent);
  }
}
