import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyService } from '../../identity/company-edit/company.service';
import { TranslocoPipe } from '@ngneat/transloco';
import { ColibriModule } from '@ac/colibri';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-admin-management-company',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoPipe,
    ColibriModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule
  ],
  templateUrl: './admin-management-company.component.html',
  styleUrl: './admin-management-company.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminManagementCompanyComponent {
  protected form: UntypedFormGroup;
  #companyService = inject(CompanyService);
  #formBuilder = inject(FormBuilder);

  constructor() {
    this.form = this.#formBuilder.group({
      id: ['', [Validators.required]],
      deletePhishingMailInformation: [false]
    });
  }

  deleteCompany() {
    if (this.form.invalid) {
      return;
    }

    const id = this.form.get('id').value;
    const deletePhishingMailInformation = this.form.get('deletePhishingMailInformation').value;

    this.#companyService
      .delete()
      .mutate({ id: id, dto: { deletePhishingMailInformation: deletePhishingMailInformation } });
  }
}
