import { computed, Injectable, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ContactWindowState {
  #state = signal({
    isOpen: false,
    subject: '',
    message: ''
  });

  isOpen = computed(() => this.#state().isOpen);

  formValues = computed(() => {
    const state = this.#state();

    return { subject: state.subject, message: state.message };
  });

  open() {
    this.#state.update(state => ({ ...state, isOpen: true }));
  }

  close() {
    this.#state.update(state => ({ ...state, isOpen: false }));
  }

  setForm(props: { subject: string; message: string }) {
    // this.#state.update(state => ({ ...state, ...props }));
  }

  clearForm() {
    // this.#state.update(state => ({ ...state, subject: '', message: '' }));
  }
}
