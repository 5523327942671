<app-back-layout [title]="'navigation.menuItem.homePage' | transloco">
  <div class="items">
    <app-cyber-portal-overview-item
      [imgUrl]="urlBuilder.getImageVariation(environment.assets.portalOverview.courseOverview, 'card')"
      imgAlt="Mann mit Stift schreibt Notizen in das Buch."
      [link]="urlBuilder.goToCourseOverview()"
      [title]="'navigation.menuItem.courseOverview' | transloco"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      *ngIf="identityService.hasRole('Cyber-Verantwortlicher')"
      [imgUrl]="urlBuilder.getImageVariation(environment.assets.portalOverview.userManagement, 'card')"
      imgALt="Mann führt ein Videokonferenzgespräch."
      [link]="urlBuilder.goToUserManagement()"
      [title]="'navigation.menuItem.userManagement' | transloco"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="urlBuilder.getImageVariation(environment.assets.portalOverview.phishingSimulation, 'card')"
      imgAlt="Mann tippt auf seinem Handy."
      [link]="urlBuilder.goToPhishingMails()"
      [title]="'navigation.menuItem.phishingSimulations' | transloco"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="urlBuilder.getImageVariation(environment.assets.portalOverview.toolkit, 'card')"
      imgAlt="Schraubenschlüssel auf einer Tastatur."
      [link]="urlBuilder.goToToolkit()"
      [title]="'navigation.menuItem.toolkit' | transloco"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      *ngIf="identityService.hasRole('Globaler Administrator') || identityService.hasRole('Produkt-Manager')"
      [imgUrl]="urlBuilder.getImageVariation(environment.assets.portalOverview.administration, 'card')"
      imgAlt="Mann tippt und schaut auf sein Dual-Screen-Setup."
      [link]="urlBuilder.goToAdministration()"
      [title]="'navigation.menuItem.administration' | transloco"
    ></app-cyber-portal-overview-item>
    <app-cyber-portal-overview-item
      [imgUrl]="urlBuilder.getImageVariation(environment.assets.portalOverview.help, 'card')"
      imgAlt="Kundenservicemitarbeiterin mit Headset, die lächelt."
      [link]="urlBuilder.goToHelp()"
      [title]="'navigation.menuItem.help' | transloco"
    ></app-cyber-portal-overview-item>
  </div>
</app-back-layout>
