import { createTaskOption, ExaminationConfiguration } from '@ac/exam';
import { environment } from '../../../../environments/environment';

export const course4Exam: ExaminationConfiguration = {
  general: { requiredCorrectTasks: 2 },
  tasks: [
    {
      type: 'single-choice',
      task: {
        heading: 'Gefahren von gekürzten Links minimieren',
        description:
          'Über das Businessnetzwerk Xing haben Ihnen drei unbekannte Kontakte die folgenden Nachrichten gesendet.<br><br>Wählen Sie die Nachricht aus, die Sie für seriös halten.<br><br>Nutzen Sie dazu <a target="_blank" href="https://checkshorturl.com">dieses Werkzeug</a>. Achten Sie auf die Links, die in Ihrem Browser unten links angezeigt werden.',
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/multiple-choice-links-1/Link1.png',
            'https://adresse.unsicher.de/schadsoftware',
            'Guten Tag, im Zuge unserer aktuellen Neukunden-Aktion haben wir ein Angebot, welches für Sie von Interesse sein könnte. Sie finden dieses hier mit einer Verlinkung https://buerozubehoer.de zu https://adresse.unsicher.de/schadsoftware. Bei Rückfragen können Sie mich natürlich jederzeit kontaktieren. Mit freundlichen Grüßen Marcel Blau.'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/multiple-choice-links-1/Link2.png',
            'https://bit.ly/3sEbj3s',
            'Guten Tag, im Zuge unserer aktuellen Neukunden-Aktion haben wir ein Angebot, welches für Sie von Interesse sein könnte. Sie finden dieses hier mit einer Verlinkung Link zu https://bit.ly/3sEbj3s. Bei Rückfragen können Sie mich natürlich jederzeit kontaktieren. Mit freundlichen Grüßen Steffen Neumann.'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/multiple-choice-links-1/Link3.png',
            'https://amzn.to/2NpuNZy',
            'Guten Tag, im Zuge unserer aktuellen Neukunden-Aktion haben wir ein Angebot, welches für Sie von Interesse sein könnte. Sie finden dieses hier mit einer Verlinkung Link zu https://amzn.to/2NpuNZy. Bei Rückfragen können Sie mich natürlich jederzeit kontaktieren. Mit freundlichen Grüßen Laura Kühner.'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/multiple-choice-links-1/Link3.png',
          'https://amzn.to/2NpuNZy',
          'Guten Tag, im Zuge unserer aktuellen Neukunden-Aktion haben wir ein Angebot, welches für Sie von Interesse sein könnte. Sie finden dieses hier mit einer Verlinkung Link zu https://amzn.to/2NpuNZy. Bei Rückfragen können Sie mich natürlich jederzeit kontaktieren. Mit freundlichen Grüßen Laura Kühner.'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt',
          wrong: `
Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
            <ul>
            <li>Nachricht 1: Unseriös, da der Link zu einer anderen Zieladresse führt, als er vorgibt.</li>
            <li>Nachricht 2: Unseriös, da der verwendete gekürzte Link auf eine gefährliche Zieladresse verweist.</li>
            <li>Nachricht 3: Seriös, da der Link auf ein glaubwürdiges Ziel verweist.</li>
            </ul>
          `
        }
      }
    },
    {
      type: 'single-choice',
      task: {
        heading: 'Authentizität anhand vom Impressum bewerten',
        description:
          'Auf der Suche nach einem neuen Edelstahllieferanten sind Sie auf zwei Unternehmen gestoßen, deren Impressen Sie hier abgebildet finden.<br><br>Wählen Sie den seriösen Geschäftspartner aus.<br><br>Nutzen Sie zur Recherche die Rückwärtssuche von <a target="_blank" href="https://www.dasoertliche.de/rueckwaertssuche/">Das Örtliche</a>.',
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-1/Impressum-1.png',
            'Impressum-1',
            'Impressum von der Firma heco GmbH mit der Telefonnummer von +49 7323 36 55-0'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-1/Impressum-2.png',
            'Impressum-2',
            'Impressum von der Firma heco GmbH mit der Telefonnummer von +49 7232 36 55-0'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-1/Impressum-2.png',
          'Impressum-2',
          'Impressum von der Firma heco GmbH mit der Telefonnummer von +49 7232 36 55-0'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt',
          wrong: `
Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
          <ul>
          <li>Impressum 1: Unseriös, da die angegebene Telefonnummer ungültig ist.</li>
          <li>Impressum 2: Seriös, da alle Pflichtangaben enthalten und korrekt sind.</li>
          </ul>
          `
        }
      }
    },
    {
      type: 'single-choice',
      task: {
        heading: 'Authentizität anhand vom Impressum bewerten',
        description:
          'Auf der Suche nach einem neuen Edelstahllieferanten sind Sie auf zwei Unternehmen gestoßen, deren Impressen Sie hier abgebildet finden.<br><br>Wählen Sie den seriösen Geschäftspartner aus.<br><br>Nutzen Sie zur Recherche das <a target="_blank" href="https://www.handelsregister.de/">Handelsregister</a>.',
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-2/Impressum-1.png',
            'Impressum-1',
            'Impressum von der Firma heco GmbH mit der Registernummer von HRB 500889'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-2/Impressum-2.png',
            'Impressum-2',
            'Impressum von der Firma heco GmbH mit der Registernummer von HRB 914512'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-2/Impressum-1.png',
          'Impressum-1',
          'Impressum von der Firma heco GmbH mit der Registernummer von HRB 500889'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt',
          wrong: `
Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
          <ul>
          <li>Impressum 1: Seriös, da alle Pflichtangaben enthalten und korrekt sind.</li>
          <li>Impressum 2: Unseriös, da die Handelsregisternummer ungültig ist.</li>
          </ul>
          `
        }
      }
    },
    {
      type: 'single-choice',
      task: {
        heading: 'Authentizität anhand vom Impressum bewerten',
        description:
          'Auf der Suche nach einem neuen Edelstahllieferanten sind Sie auf zwei Unternehmen gestoßen, deren Impressen Sie hier abgebildet finden.<br><br>Wählen Sie den seriösen Geschäftspartner aus. Achten Sie auf die Vollständigkeit des Impressums.',
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-3/Impressum-1.png',
            'Impressum-1',
            'Impressum von der Firma heco GmbH ohne Adresse'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-3/Impressum-2.png',
            'Impressum-2',
            'Impressum von der Firma heco GmbH, mit Adresse von Am Eisengraben 5, 75196 Remchingen.'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-impressum-3/Impressum-2.png',
          'Impressum-2'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt',
          wrong: `
Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
          <ul>
          <li>Impressum 1: Unseriös, da das Impressum keinen Firmensitz aufweist.</li>
          <li>Impressum 2: Seriös, da alle Pflichtangaben enthalten und korrekt sind.</li>
          </ul>
          `
        }
      }
    },
    {
      type: 'temporary-email',
      task: {
        heading: 'Eigene E-Mail-Adresse sparsam einsetzen',
        description:
          'Sie wollen sich die neuesten Umsatzzahlen für Ihre Branche herunterladen. Die Webseite fordert Sie zur Eingabe Ihrer E-Mail-Adresse auf, damit Sie die Daten herunterladen können.<br>Nutzen Sie dazu <a target="_blank" href="https://temp-mail.org">dieses Werkzeug</a>, um eine temporäre E-Mail-Adresse zu erstellen.<br><br>Die Webseite meldet: "Bitte geben Sie Ihre E-Mail-Adresse an. Wir schicken Ihnen den Code zu, womit Sie die Umsatzzahlen herunterladen können."',
        questionOne: {
          description:
            'Bitte geben Sie Ihre E-Mail-Adresse an. Wir schicken Ihnen den Code zu, womit Sie die Umsatzzahlen herunterladen können.',
          inputField: {
            type: 'input',
            validationEndpoint: environment.apiUrl + environment.apiRoutes.tempMails,
            validationErrorMessage: 'Es handelt sich bei Ihrer Eingabe nicht um eine E-Mail-Adresse von temp-mail.org'
          }
        },
        questionTwo: {
          description:
            'Eine E-Mail mit Ihrem Code wurde an Ihre E-Mail-Adresse geschickt. Bitte geben Sie diesen hier ein:',
          inputField: { type: 'input' }
        },
        solution: 'xxxxxxx',
        summary: {
          wrong: 'Der eingegebene Code stimmt nicht überein. Der erwartete Code lautet: "865321".'
        }
      }
    },
    {
      type: 'single-choice',
      task: {
        heading: 'Authentizität anhand vom Inhalt bewerten',
        description:
          'Sie sind in Ihrem Unternehmen für den Einkauf von Edelstahl zuständig. Für einen Kundenauftrag müssen Sie neue Edelstahl-Rohre einkaufen. Im Internet sind Sie auf die folgenden Zulieferer gestoßen.<br><br>Überprüfen Sie die Angebote auf deren Seriosität. Wählen Sie den seriösesten Zulieferer aus.',
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-1/Text1.png',
            'Text1',
            'Ihr kompetenter Edelstahl Zulieferer. Sie sind auf der Suche nach einem neuen Edelstahl Zulieferer? Dann sind Sie bei uns genau richtig. Wir bieten Ihnen hohe Qualitätsstandards und schnelle Lieferzeiten. Jegliche von uns hergestellte Ware entspricht den DIN-Normen. Sie können uns gerne kontaktieren. Tel.: +49721935163870'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-1/Text2.png',
            'Text2',
            'Ihr kompetenter Edelstahl Zulieferer. Bei uns erhalten du hochwertige Edelstahlrohre in diversen Ausführungen, mit unterschiedlichem Durchmesser und individuell definierbaren Längen. Daneben praesentieren wir dir notwendige Zuberhorteile für Edelstahlgelander sowie Edelstahl Volmaterial.'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-1/Text1.png',
          'Text1',
          'Ihr kompetenter Edelstahl Zulieferer. Sie sind auf der Suche nach einem neuen Edelstahl Zulieferer? Dann sind Sie bei uns genau richtig. Wir bieten Ihnen hohe Qualitätsstandards und schnelle Lieferzeiten. Jegliche von uns hergestellte Ware entspricht den DIN-Normen. Sie können uns gerne kontaktieren. Tel.: +49721935163870'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt.',
          wrong: `
Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
          <ul>
          <li>Text 1: Seriös, da hochwertiger redaktioneller Inhalt.</li>
          <li>Text 2: Unseriös, da Text augenscheinlich automatisch generiert wurde.</li>
</ul>
          `
        }
      }
    },
    {
      type: 'single-choice',
      task: {
        heading: 'Authentizität anhand vom Inhalt bewerten',
        description:
          'Sie sind der Büro-Manager Ihres Unternehmens und suchen aktuell nach neuer Büroausstattung. Im Internet sind Sie auf die folgenden Zulieferer gestoßen.<br><br>Überprüfen Sie die Angebote auf deren Seriosität. Wählen Sie den seriösesten Zulieferer aus.',
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-2/Text1.png',
            'Text1',
            'Die perfekte Ausstattung für Ihr Büro Seit über 30 Jahren begeistern wir Mitarbeiter verschiedenster Unternehmen mit stilvollen, funktionalen und ergonomischen Möbeln für Ihr Arbeitsumfeld, denn wir kennen die unterschiedlichsten Anforderungen, die an einen modernen Büro-Arbeitsplatz gestellt werden. Sie können uns gerne kontaktieren. Tel.: +49 721939284091. E-Mail: kontakt@bueromanagement.de'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-2/Text2.png',
            'Text2',
            'Die perfekte Ausstattung für Ihr Büro Sie suchen neu Bürpausstattung? Dann haben wir für sie das perfekte Ausstattung für Ihren Büro. Wir bieten das ihnen für nur 300€. Diese Ausrüstunk ergänzt Ihr Büro bestens.'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-2/Text3.png',
            'Text3',
            'Die perfekte Ausstattung für Ihr Büro Wenn Sie auf der Suche nach neuen hochwertigen Büromaterialien sind, haben wir genau jetzt das perfekte Angebot für Sie. Es handelt sich hierbei um einen Bürostuhl inklusive Bürotisch und Monitor. Der Listenpreis beträgt 800€. Schlagen Sie in den nächsten 24 Stunden zu, gewähren wir Ihnen zusätzlich 8% Skonto.'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-2/Text4.png',
            'Text4',
            'Die perfekte Ausstattung für Ihr Büro Sie brauchen neue Büromöbel oder neue Monitore? Dann sind Sie bei uns genau richtig! Wir bieten Ihnen unteranderem qualitative Bürotische inklusive Bürostühle für einen unschlagbaren Preis von unter 80€.'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-2/Text1.png',
          'Text1',
          'Die perfekte Ausstattung für Ihr Büro Seit über 30 Jahren begeistern wir Mitarbeiter verschiedenster Unternehmen mit stilvollen, funktionalen und ergonomischen Möbeln für Ihr Arbeitsumfeld, denn wir kennen die unterschiedlichsten Anforderungen, die an einen modernen Büro-Arbeitsplatz gestellt werden. Sie können uns gerne kontaktieren. Tel.: +49 721939284091. E-Mail: kontakt@bueromanagement.de'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt.',
          wrong: `
Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
          <ul>
          <li>Text 1: Seriös, da hochwertiger redaktioneller Inhalt.</li>
          <li>Text 2: Unseriös, da Text augenscheinlich automatisch generiert wurde.</li>
          <li>Text 3: Unseriös, da Sie in diesem Angebot unter Druck gesetzt werden.</li>
          <li>Text 4: Unseriös, da das Angebot zu gut ist um wahr zu sein.</li>
</ul>
          `
        }
      }
    },
    {
      type: 'single-choice',
      task: {
        heading: 'Authentizität anhand vom Inhalt bewerten',
        description:
          'Sie sind der Fuhrpark-Manager Ihres Unternehmens und suchen aktuell nach einem neuen Flottenwagen. Im Internet sind Sie auf die folgenden Zulieferer gestoßen.<br><br>Überprüfen Sie die Angebote auf deren Seriosität. Wählen Sie den seriösesten Zulieferer aus.',
        options: [
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-3/Text1.png',
            'Text1',
            'Der perfekte Wagen für Ihren Fuhrpark Sie sind auf der Suche nach einem neuen Wagen für Ihren Fuhrpark? Wir haben genau das passende Fahrzeug für Sie. Es handelt sich hierbei um einen: Audi A3 Limousine für unschlagbare 10.000€'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-3/Text2.png',
            'Text2',
            'Der perfekte Wagen für Ihren Fuhrpark Wenn Sie auf der Suche nach einem neuen Wagen für Ihren Fuhrpark sind, haben wir genau jetzt das perfekte Angebot für Sie. Es handelt sich hierbei um einen Audi A4. Der Listenpreis beträgt 30.000€. Schlagen Sie in den nächsten 24 Stunden zu, gewähren wir Ihnen zusätzlich 5% Skonto.'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-3/Text3.png',
            'Text3',
            'Der perfekte wagen für Ihren Fuhrpark Wir haben genau passenden flottenwagen für Ihr Fuhrpark. Es handelt sich hierbei um einen neuen Audi A1. Wir bieten ihnen das für 20.000€ an. Der Audi A1 ist die optimale erganzung für Ihren fuhrpark.'
          ),
          createTaskOption(
            'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-3/Text4.png',
            'Text4',
            'Der perfekte Wagen für Ihren Fuhrpark In einer sich schnell verändernden Welt braucht man einen Begleiter, der für neue Herausforderungen gewappnet ist. Sie können uns gerne kontaktieren. Tel.: +49 721935163010 E-Mail: kontakt@autohaus.de'
          )
        ],
        solution: createTaskOption(
          'https://dl.co-it.eu/wuerttembergische/awareness-center/exams/course4/single-choice-content-3/Text4.png',
          'Text4',
          'Der perfekte Wagen für Ihren Fuhrpark In einer sich schnell verändernden Welt braucht man einen Begleiter, der für neue Herausforderungen gewappnet ist. Sie können uns gerne kontaktieren. Tel.: +49 721935163010 E-Mail: kontakt@autohaus.de'
        ),
        summary: {
          correct: 'Super, Sie haben die korrekte Möglichkeit ausgewählt.',
          wrong: `
Ihre Antwort ist leider falsch. Nachfolgend finden Sie die Erläuterung zur korrekten Lösung:
          <ul>
          <li>Text 1: Unseriös, da das Angebot zu gut ist um wahr zu sein.</li>
          <li>Text 2: Unseriös, da Sie in diesem Angebot unter Druck gesetzt werden.</li>
          <li>Text 3: Unseriös, da Text augenscheinlich automatisch generiert wurde.</li>
          <li>Text 4: Seriös, da hochwertiger redaktioneller Inhalt.</li>
</ul>
          `
        }
      }
    }
  ]
};
