import { ColibriModule } from '@ac/colibri';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideCharts, withDefaultRegisterables, BaseChartDirective } from 'ng2-charts';
import { LayoutsModule } from '../layouts/layouts.module';
import { TranslateModule } from '../translate.module';
import { PhishingMailsCompanyStatisticComponent } from './phishing-mails-company-statistic/phishing-mails-company-statistic.component';
import { PhishingMailsLandingPageComponent } from './phishing-mails-landing-page/phishing-mails-landing-page.component';
import { PhishingMailsStatisticTotalChartComponent } from './phishing-mails-statistic-total-chart/phishing-mails-statistic-total-chart.component';
import { PhishingMailsUserStatisticComponent } from './phishing-mails-user-statistic/phishing-mails-user-statistic.component';
import { PhishingMailsComponent } from './phishing-mails/phishing-mails.component';
import { PhishingMailsStatisticUserDepartmentsChartComponent } from './phishing-mails-statistic-user-departments-chart/phishing-mails-statistic-user-departments-chart.component';
import { PhishingMailsStatisticCompanyClickComparisonComponent } from './phishing-mails-statistic-company-click-comparison/phishing-mails-statistic-company-click-comparison.component';
import { PhishingMailsStatisticCompanyRankingComponent } from './phishing-mails-statistic-company-ranking/phishing-mails-statistic-company-ranking.component';
import { MatIconModule } from '@angular/material/icon';
import { AdministrationModule } from '../administration/administration.module';
import { NeedsPackageUpgradeDirective } from '../subscriptions/needs-package-upgrade.directive';
import { PhishingMailsTotalClicksStatisticComponent } from './phishing-mails-total-clicks-statistic/phishing-mails-total-clicks-statistic.component';
import { RouterLink, RouterOutlet } from '@angular/router';
import { HelpButtonComponent } from '@ac/colibri';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';

@NgModule({
  declarations: [
    PhishingMailsComponent,
    PhishingMailsCompanyStatisticComponent,
    PhishingMailsUserStatisticComponent,
    PhishingMailsStatisticTotalChartComponent,
    PhishingMailsLandingPageComponent,
    PhishingMailsStatisticUserDepartmentsChartComponent,
    PhishingMailsStatisticCompanyClickComparisonComponent,
    PhishingMailsStatisticCompanyRankingComponent
  ],
  imports: [
    CommonModule,
    ColibriModule,
    LayoutsModule,
    TranslateModule,
    MatIconModule,
    AdministrationModule,
    NeedsPackageUpgradeDirective,
    PhishingMailsTotalClicksStatisticComponent,
    RouterOutlet,
    RouterLink,
    HelpButtonComponent,
    MatTableModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatListModule,
    BaseChartDirective
  ],
  exports: [
    PhishingMailsComponent,
    PhishingMailsCompanyStatisticComponent,
    PhishingMailsUserStatisticComponent,
    PhishingMailsLandingPageComponent
  ],
  providers: [
    provideCharts(
        withDefaultRegisterables(),
        {
          defaults: {
            locale: "de-DE",
            font: {
              family: "WWTypeWeb, sans-serif",
              lineHeight: 1,
              size: 14
            }
          }
    })
  ]
})
export class PhishingMailsModule {}
