import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RansomClauseComponent } from './ransom-clause/ransom-clause.component';
import { ExamModule } from '@ac/exam';
import { ColibriModule, RecaptchaConsentComponent } from '@ac/colibri';
import { TextConfirmationComponent } from './text-confirmation/text-confirmation.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VideoConfirmationComponent } from './video-confirmation/video-confirmation.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { RansomClauseIntroComponent } from './ransom-clause-intro/ransom-clause-intro.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { RansomClauseTestPassedComponent } from './ransom-clause-test-passed/ransom-clause-test-passed.component';
import { RansomClauseTestFailedComponent } from './ransom-clause-test-failed/ransom-clause-test-failed.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    RansomClauseComponent,
    TextConfirmationComponent,
    VideoConfirmationComponent,
    RansomClauseIntroComponent,
    RansomClauseTestPassedComponent,
    RansomClauseTestFailedComponent
  ],
  imports: [
    CommonModule,
    ExamModule,
    ColibriModule,
    MatIconModule,
    TranslocoModule,
    FormsModule,
    CdkStepperModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaConsentComponent,
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: []
})
export class RansomClausesModule {}
