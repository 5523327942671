import { inject, Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { CourseCmsDto } from '@ac/models';
import { activeCourseContext } from '../store/course.selectors';
import {
  EntityActionFactory,
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
  EntityOp
} from '@ngrx/data';
import { Store } from '@ngrx/store';
import { toSignal } from '@angular/core/rxjs-interop';
import { environment } from '@env';
import { IdentityService } from '../../identity/identity.service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class CmsClient {
  #http = inject(HttpClient);

  #snackBar = inject(MatSnackBar);

  #store = inject(Store);
  #entityActionFactory = inject(EntityActionFactory);
  #entityCollectionServiceFactory = inject(EntityCollectionServiceElementsFactory);

  #identity = inject(IdentityService);

  readonly courseContext$ = this.#store.select(activeCourseContext);
  readonly courseContext = toSignal(this.courseContext$);

  readonly entityMap = toSignal(this.entityMap$());
  readonly entities = toSignal(this.entities$());
  readonly collectionEntityClient = toSignal(this.collectionEntityClient$());

  get #companyId() {
    return this.#identity.getCompanyId();
  }

  get #courseUpdateUrlByContext() {
    const courseContext = this.courseContext();
    if (courseContext === 'CourseCMS') {
      return `${environment.apiUrl}${environment.apiRoutes.courseCms}`;
    } else if (courseContext === 'CompanyCourse') {
      return `${environment.apiUrl}/companies/${this.#companyId}/courses`;
    } else {
      throw new Error(`Detected context ${courseContext}, for which no UpdateUrl has been defined.`);
    }
  }

  collectionEntityClient$() {
    return this.courseContext$.pipe(
      map(context => new EntityCollectionServiceBase<CourseCmsDto>(context, this.#entityCollectionServiceFactory))
    );
  }

  entityMap$() {
    return this.collectionEntityClient$().pipe(switchMap(client => client.entityMap$));
  }

  entities$() {
    return this.collectionEntityClient$().pipe(switchMap(client => client.entities$));
  }

  changeState$() {
    return this.collectionEntityClient$().pipe(switchMap(client => client.changeState$));
  }

  saveChanges() {
    const courses = this.entities();
    const correctedCourses = courses.map(course => fillInRequiredInformation(course));

    // Send and save all courses
    this.#http.post(this.#courseUpdateUrlByContext, { courses: correctedCourses }).subscribe();

    // Reset Changed State & Disable Save and Reset-Button in CMS
    this.#store.dispatch(this.#entityActionFactory.create<CourseCmsDto>(this.courseContext(), EntityOp.COMMIT_ALL));

    this.#snackBar.open('Änderungen erfolgreich gespeichert.', 'OK', { duration: 3000 });
  }

  undoChanges() {
    this.#store.dispatch(this.#entityActionFactory.create<CourseCmsDto>(this.courseContext(), EntityOp.UNDO_ALL));
  }
}

/*
 * Although we have form validation, it currently acts has hints.
 * The user can click save, and invalid data will be sent to the API.
 * The API does not validate the course data, either.
 *
 * At the moment, we do not want to change the UX.
 * If we switch form validation to throw errors, we would need to think about what we do with the buttons.
 * How should a user know which form error disabled the button?
 *
 * Since the current behavior exists for a long time and user can send invalid data, we decided
 * to fill in information if needed.
 */
function fillInRequiredInformation(course: CourseCmsDto): CourseCmsDto {
  return {
    ...course,
    title: course.title || 'Kursentwurf',
    abstract: course.abstract || 'Beschreibungsentwurf für den Kurs',
    modules: course.modules.map(module => ({
      ...module,
      title: module.title || 'Modulentwurf',
      lessons: module.lessons.map(lesson => ({
        ...lesson,
        title: lesson.title || 'Lektionsentwurf'
      }))
    }))
  };
}
