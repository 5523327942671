import { SingleChoiceTask, TaskCompletion, TaskComponent } from '@ac/exam';
import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'ac-single-choice',
  template: `
    <ac-task-header [configuration]="configuration"></ac-task-header>

    <mat-radio-group
      [formControl]="selectedOption"
      aria-label="Select an option"
      class="radio-group"
      data-testid="option-card-list"
    >
      <label
        [attr.for]="matRadioButton.id"
        class="option-card"
        data-test="option-card"
        data-testid="option-card"
        *ngFor="let option of configuration.options; let i = index"
      >
        <div class="option-card-content">
          <div class="option-card-badge-wrapper">
            <span class="badge">{{ i + 1 }}</span>
          </div>
          <a
            [href]="option.value"
            (click)="$event.preventDefault()"
            tabindex="-1"
          >
            <img
              tabindex="-1"
              [src]="option.label"
              [attr.alt]="option.alt"
            />
          </a>
        </div>
        <div class="option-card-action">
          <mat-radio-button
            data-testid="card-radio-button"
            #matRadioButton
            [value]="option.value"
            color="accent"
          ></mat-radio-button>
        </div>
      </label>
    </mat-radio-group>

    <div class="commands">
      <button
        wvButton
        [disabled]="formSubmitted || !selectedOption.value"
        (click)="submitAnswer()"
        appearance="secondary"
        data-test="button-submit-solution"
        data-testid="button-submit-solution"
      >
        {{ 'exam.submitAnswerButton' | transloco }}
      </button>
    </div>
  `,
  styleUrls: ['./single-choice.component.scss']
})
export class SingleChoiceComponent implements TaskComponent<SingleChoiceTask> {
  configuration: SingleChoiceTask;
  selectedOption = new UntypedFormControl(null);
  formSubmitted = false;
  private complete$$ = new Subject<TaskCompletion>();

  complete = () => this.complete$$.asObservable();

  prepare(configuration: SingleChoiceTask): void {
    this.configuration = configuration;
  }

  submitAnswer() {
    this.formSubmitted = true;

    if (this.isCorrect(this.selectedOption.value)) {
      this.complete$$.next({ isCorrect: true });
    } else {
      this.complete$$.next({ isCorrect: false });
    }
  }

  private isCorrect(selectedOption: string): boolean {
    return this.configuration.solution.value == selectedOption;
  }
}
