import { Component, effect, inject, input, viewChild } from '@angular/core';
import { ChartData, ChartOptions, ChartType, Tooltip } from 'chart.js';
import { DefaultChartOptionsService } from '../default-chart-options.service';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-phishing-mails-statistic-company-click-comparison.',
  templateUrl: './phishing-mails-statistic-company-click-comparison.component.html',
  styleUrls: ['./phishing-mails-statistic-company-click-comparison.component.scss']
})
export class PhishingMailsStatisticCompanyClickComparisonComponent {
  clickComparisons = input.required<Record<string, number>>();
  chart = viewChild(BaseChartDirective);

  chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        yAlign: 'bottom',
        position: 'nearest'
      },
      legend: {
        position: 'bottom',
        align: 'start'
      }
    },
    scales: {
      x: {
        display: false,
        max: 0 //Anzahl der Werte in Dataset - 1
      }
    }
  };
  chartData: ChartData<'bar', number[]> = { datasets: [], labels: [] };
  chartType: ChartType = 'bar';
  #defaultChartOptions = inject(DefaultChartOptionsService);

  constructor() {
    effect(() => {
      this.chartData.datasets = [];

      let i = 0;

      for (const comparison in this.clickComparisons()) {
        this.chartData.datasets.push({
          data: [this.clickComparisons()[comparison]],
          label: comparison,
          hoverBackgroundColor: this.#defaultChartOptions.getDefaultColors().hoverBackgroundColor[i],
          backgroundColor: this.#defaultChartOptions.getDefaultColors().backgroundColor[i],
          hoverBorderColor: this.#defaultChartOptions.getDefaultColors().hoverBorderColor
        });

        i++;
      }

      this.chartData.labels = Object.keys(this.clickComparisons());

      if (this.chart()) {
        this.chart().update();
      }
    });
  }
}
