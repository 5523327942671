import { Component, computed, effect, inject, input, viewChild } from '@angular/core';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { DefaultChartOptionsService } from '../default-chart-options.service';
import { TranslocoService } from '@ngneat/transloco';
import { BaseChartDirective } from 'ng2-charts';
import { countValues } from '../../utils/count-values';
import { PhishingClickInfoDto } from '@ac/models';

@Component({
  selector: 'app-phishing-mails-statistic-user-departments-chart',
  templateUrl: './phishing-mails-statistic-user-departments-chart.component.html',
  styleUrls: ['./phishing-mails-statistic-user-departments-chart.component.scss']
})
export class PhishingMailsStatisticUserDepartmentsChartComponent {
  phishingPerDepartment = input.required<Record<string, Record<string, PhishingClickInfoDto>>>();
  minHeight = computed(
    () =>
      `${(Object.keys(this.phishingPerDepartment()).length + countValues(this.phishingPerDepartment())) * 15 + 85}px`
  );
  chart = viewChild(BaseChartDirective);
  privacyIssue = true;
  packageTooLow = true;
  chartData: ChartData<'bar', number[]> = { datasets: [], labels: [] };
  chartType: ChartType = 'bar';
  #defaultChartOptions = inject(DefaultChartOptionsService);
  #transloco = inject(TranslocoService);
  chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start'
      },
      tooltip: {
        position: 'nearest',
        xAlign: 'left',
        callbacks: {
          label: item => {
            return [
              item.dataset.label,
              `Klickrate: ${item.formattedValue} %`,
              `Anzahl Klicks: ${this.phishingPerDepartment()[item.label][item.dataset.label].clickCount}`,
              `Anzahl Versendet: ${this.phishingPerDepartment()[item.label][item.dataset.label].sentCount}`
            ];
          }
        }
      }
    },
    indexAxis: 'y',
    scales: {
      x: {
        title: {
          text: this.#transloco.translate('phishingSimulations.statistic.company.clickRateHeading'),
          display: true
        },
        ticks: {
          callback: value =>
            ((value as number) / 100).toLocaleString('de-DE', {
              style: 'percent',
              maximumFractionDigits: 1
            })
        }
      },
      y: {
        title: {
          text: this.#transloco.translate('phishingSimulations.statistic.company.departmentAxis'),
          display: true,
          font: {
            size: 12
          }
        }
      }
    }
  };

  constructor() {
    effect(() => {
      this.privacyIssue = Object.keys(this.phishingPerDepartment()).length === 0;

      if (this.privacyIssue) return;

      this.packageTooLow = Object.keys(Object.values(this.phishingPerDepartment())[0]).length === 0;

      if (this.packageTooLow) return;

      const firstDepartment = Object.values(this.phishingPerDepartment())[0];
      const departmentKeys = Object.keys(firstDepartment);
      const departmentCount = departmentKeys.length;

      this.chartData.datasets = [];

      for (let scopeIndex = 0; scopeIndex < departmentCount; scopeIndex++) {
        const dataValues = Object.values(this.phishingPerDepartment()).map(
          x => Object.values(x)[scopeIndex].clickRate * 100
        );
        const backgroundColor = this.#defaultChartOptions.getDefaultColors().backgroundColor[scopeIndex];
        const hoverBackgroundColor = this.#defaultChartOptions.getDefaultColors().hoverBackgroundColor[scopeIndex];
        const hoverBorderColor = this.#defaultChartOptions.getDefaultColors().hoverBorderColor;
        const label = departmentKeys[scopeIndex];

        const dataset = { data: dataValues, backgroundColor, hoverBackgroundColor, hoverBorderColor, label };
        this.chartData.datasets.push(dataset);
      }

      this.chartData.labels = Object.keys(this.phishingPerDepartment());

      if (this.chart()) {
        this.chart().update();
      }
    });
  }
}
