import { Component } from '@angular/core';
import { WhitelistAccessDialogComponent } from '../whitelist-access-dialog/whitelist-access-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-whitelisted-accesses',
  templateUrl: './whitelisted-accesses.component.html',
  styleUrls: ['./whitelisted-accesses.component.scss']
})
export class WhitelistedAccessesComponent {
  constructor(private dialog: MatDialog) {}

  openInvitationDialog() {
    this.dialog.open(WhitelistAccessDialogComponent, {
      autoFocus: false
    });
  }
}
