import { Injectable, signal, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, of } from 'rxjs';
import { finalize, switchMap, withLatestFrom } from 'rxjs/operators';
import { LoadingDialogData } from './loading-dialog-data';
import { LoadingDialogComponent } from './loading-dialog.component';

@Injectable({ providedIn: 'root' })
export class LoadingDialogService {
  isOpen = signal(false);
  private loadingDialogData$$ = new BehaviorSubject<LoadingDialogData>(null);

  constructor(private dialog: MatDialog) {}

  open<T>(component: Type<T>, data?: LoadingDialogData): void {
    if (!this.isOpen()) {
      this.openDialog();
      this.isOpen.set(true);
    }

    if (data) {
      this.loadingDialogData$$.next(data);
    }
  }

  close(): void {
    this.dialog.closeAll();
    this.isOpen.set(false);
  }

  setData(state: LoadingDialogData): void {
    this.loadingDialogData$$.next(state);
  }

  private openDialog() {
    this.dialog
      .open(LoadingDialogComponent, {
        data: this.loadingDialogData$$,
        autoFocus: true,
        hasBackdrop: true,
        disableClose: true,
        maxWidth: 700
      })
      .afterClosed()
      .pipe(
        finalize(() => {
          this.isOpen.set(false);
          this.loadingDialogData$$.next(null);
        }),
        withLatestFrom(this.loadingDialogData$$),
        switchMap(([, dialogData]) => {
          if (dialogData && dialogData.afterClose) {
            return dialogData.afterClose?.();
          }
          return of(null);
        })
      )
      .subscribe({
        error: err => console.error('[LoadingDialogService] Closing action has failed', err)
      });
  }
}
