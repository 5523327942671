<a
  [href]="environment.urls.author"
  data-test="text-author"
  target="_blank"
>
  <img
    class="min-w-24 w-24"
    [src]="environment.assets.logo"
    alt="co-IT Logo"
  />
</a>
<div class="links">
  <a
    [href]="environment.urls.imprint"
    data-test="link-imprint"
    target="_blank"
  >
    {{ 'footer.imprint' | transloco }}
  </a>
  <span>|</span>
  <a
    [href]="environment.urls.privacy"
    data-test="link-privacy"
    target="_blank"
  >
    {{ 'footer.privacy' | transloco }}
  </a>
  <span>|</span>
  <a
    [href]="environment.urls.termsOfUse"
    data-test="link-termsOfUse"
    target="_blank"
  >
    {{ 'footer.termsOfUse' | transloco }}
  </a>
  <span>|</span>
  <a
    [href]="environment.urls.scopeOfServices"
    data-test="link-scopeOfServices"
    target="_blank"
  >
    {{ 'footer.scopeOfServices' | transloco }}
  </a>
  <span>|</span>
  <a
    [href]="environment.resources.helpAdminConfiguration"
    data-test="link-helpAdminConfiguration"
    target="_blank"
  >
    {{ 'footer.helpAdminConfiguration' | transloco }}
  </a>
</div>
