<mat-card *ngIf="module">
  <mat-card-content class="!flex justify-between items-center">
    <button
      [routerLink]="['..', 'modules', module.id, 'edit']"
      class="title"
    >
      {{ module.title }}
    </button>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
