<form
  *ngIf="lesson$ | async as lesson"
  [formGroup]="formGroup"
>
  <app-back-layout
    class="-translate-x-12"
    [backUrl]="moduleUrl()"
    [title]="lesson.title"
  ></app-back-layout>

  <span class="px-[16px]">
    <small>{{ 'cms.lesson.id' | transloco }}: {{ lesson.id }}</small>
  </span>
  <mat-form-field>
    <mat-label>{{ 'cms.lesson.title' | transloco }}</mat-label>
    <input
      formControlName="title"
      matInput
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'cms.lesson.duration' | transloco }}</mat-label>
    <input
      formControlName="duration"
      matInput
      type="number"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'cms.lesson.streamUrl' | transloco }}</mat-label>
    <input
      formControlName="streamUrl"
      matInput
    />
  </mat-form-field>
  <div
    class="resources"
    formArrayName="resources"
  >
    <h3>{{ 'cms.resource.heading' | transloco }}</h3>
    <button
      (click)="addResource()"
      color="primary"
      mat-raised-button
    >
      {{ 'cms.addButton' | transloco }}
    </button>
    <div
      *ngFor="let resource of formGroup.controls['resources']['controls']; index as i"
      class="mt-4"
    >
      <mat-card
        [formGroupName]="i"
        class="resource"
      >
        <mat-card-content class="!flex justify-between items-center w-full gap-1">
          <mat-form-field class="w-full">
            <mat-label>{{ 'cms.resource.name' | transloco }}</mat-label>
            <input
              formControlName="name"
              matInput
            />
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>{{ 'cms.resource.url' | transloco }}</mat-label>
            <input
              formControlName="url"
              matInput
            />
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>{{ 'cms.resource.description' | transloco }}</mat-label>
            <input
              formControlName="description"
              matInput
            />
          </mat-form-field>
          <div>
            <button
              (click)="removeResource(i)"
              [matTooltip]="'tooltip.delete' | transloco"
              mat-icon-button
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div
    class="resources"
    formArrayName="captions"
  >
    <h3>{{ 'cms.subtitle.heading' | transloco }}</h3>
    <button
      (click)="addCaption()"
      color="primary"
      mat-raised-button
    >
      {{ 'cms.addButton' | transloco }}
    </button>
    <div
      *ngFor="let resource of formGroup.controls['captions']['controls']; index as i"
      class="mt-4"
    >
      <mat-card
        [formGroupName]="i"
        class="resource w-full"
      >
        <mat-card-content class="!flex justify-between items-center w-full gap-1">
          <mat-form-field class="w-full">
            <mat-label>{{ 'cms.subtitle.label' | transloco }}</mat-label>
            <input
              formControlName="label"
              matInput
            />
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>{{ 'cms.subtitle.source' | transloco }}</mat-label>
            <input
              formControlName="source"
              matInput
            />
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>{{ 'cms.subtitle.language' | transloco }}</mat-label>
            <input
              formControlName="language"
              matInput
            />
          </mat-form-field>
          <div class="flex justify-center items-center">
            <button
              (click)="removeCaption(i)"
              [matTooltip]="'tooltip.delete' | transloco"
              mat-icon-button
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</form>
