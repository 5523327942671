<div class="text-container">
  <h1>{{ 'registration.stepper.step3.title' | transloco }}</h1>
  <p>{{ 'registration.accountant.description' | transloco | renderHTML }}</p>
  <p>{{ 'app.disclaimer' | transloco | renderHTML }}</p>
  <p>{{ 'app.consentInfo' | transloco | renderHTML }}</p>
</div>
<div class="form-logo-container">
  <img
    [src]="environment.assets.logo"
    alt="co-IT Logo"
  />
</div>
<div class="content-container">
  <wv-card
    [title]="'registration.stepper.step3.title' | transloco"
    [overline]="'registration.stepper.step3.label' | transloco"
  >
    <div class="form-container">
      <ng-container *ngIf="passwordPolicy$ | async as passwordPolicy">
        <app-accountant-registration-form
          (back)="goBack()"
          (submitForm)="emitAccountantDetails($event)"
          [passwordPolicy]="passwordPolicy"
        ></app-accountant-registration-form>
      </ng-container>
    </div>
  </wv-card>
  <div class="video-container">
    <app-videoplayer
      class="shadow-xl"
      *ngIf="video"
      [autoplay]="false"
      [delay]="0"
      [resetOnEnd]="true"
      [showPoster]="false"
      [video]="video"
      videoContainer="accountant-registration-video"
    ></app-videoplayer>
    <p>{{ 'registration.accountantVideoDescription' | transloco | renderHTML }}</p>
  </div>
</div>
