<table
  *ngIf="dataSource"
  [dataSource]="dataSource"
  mat-table
  matSort
>
  <ng-container matColumnDef="accountantEmail">
    <th
      *matHeaderCellDef
      mat-header-cell
      mat-sort-header
    >
      {{ 'tableColumn.email' | transloco }}
    </th>
    <td
      *matCellDef="let access"
      mat-cell
      data-testid="list-entry-email"
    >
      {{ access.accountantEmail }}
    </td>
  </ng-container>
  <ng-container matColumnDef="companyName">
    <th
      *matHeaderCellDef
      mat-header-cell
      mat-sort-header
    >
      {{ 'tableColumn.companyName' | transloco }}
    </th>
    <td
      *matCellDef="let access"
      mat-cell
    >
      {{ access.companyName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="isRegistrationPending">
    <th
      *matHeaderCellDef
      mat-header-cell
      mat-sort-header
    >
      {{ 'tableColumn.status' | transloco }}
    </th>
    <td
      *matCellDef="let access"
      mat-cell
    >
      {{ access.isRegistrationPending ? ('registration.pending' | transloco) : ('registration.completed' | transloco) }}
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th
      *matHeaderCellDef
      mat-header-cell
    ></th>
    <td
      *matCellDef="let access"
      mat-cell
    >
      <div class="table-actions">
        <button
          (click)="resendInvitation(access)"
          *ngIf="access.isRegistrationPending"
          [matTooltip]="'tooltip.resendInvitation' | transloco"
          color="accent"
          mat-icon-button
          [attr.aria-label]="'tooltip.resendInvitation' | transloco"
        >
          <mat-icon svgIcon="email-sync"></mat-icon>
        </button>
        <button
          data-testid="button-delete-pending-registration"
          (click)="deleteInvitation(access)"
          *ngIf="access.isRegistrationPending"
          [matTooltip]="'tooltip.delete' | transloco"
          color="accent"
          [attr.aria-label]="'tooltip.delete' | transloco"
          mat-icon-button
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>
  <tr
    *matHeaderRowDef="displayedColumns"
    mat-header-row
  ></tr>
  <tr
    data-testid="list-item"
    *matRowDef="let row; columns: displayedColumns"
    mat-row
  ></tr>
</table>
