<div
  class="dialog-title"
  mat-dialog-title
>
  <span>{{ 'userManagement.invitation.title' | transloco | renderHTML }}</span>
  <button
    (click)="dialogRef.close()"
    [tabIndex]="-1"
    mat-icon-button
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <mat-tab-group
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    [color]="'accent'"
    [disableRipple]="true"
    [dynamicHeight]="true"
  >
    <mat-tab [label]="'userManagement.invitation.list.title' | transloco">
      <div class="invite-content">
        <app-user-invitation-list-form></app-user-invitation-list-form>
      </div>
    </mat-tab>
    <mat-tab [label]="'userManagement.invitation.file.title' | transloco">
      <div
        class="invite-content"
        data-testid="tab-mass-import"
      >
        <app-user-invitation-file-form></app-user-invitation-file-form>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
