import { CheckResultDto } from '@ac/models';
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CourseExamReporter } from './course-exam-reporter.service';
import { CourseExamRepository } from './course-exam-repository.service';

@Component({
  selector: 'app-course-exam',
  templateUrl: './course-exam.component.html',
  styleUrls: ['./course-exam.component.scss']
})
export class CourseExamComponent {
  @Input() numberOfTasks: number;
  @Input() requiredNumberOfCorrectAnswers: number;

  examStarted = false;

  examinationConfiguration = this.courseExamRepository.getExamByCourseId(this.route.snapshot.params.courseId);

  constructor(
    private courseExamReporter: CourseExamReporter,
    private courseExamRepository: CourseExamRepository,
    private route: ActivatedRoute
  ) {}

  startExam() {
    this.examStarted = true;
  }

  reportResults(examCompletion: { correctAnswers: number }) {
    const courseId = this.route.snapshot.params.courseId;

    const dto: CheckResultDto = {
      correctAnswers: examCompletion.correctAnswers,
      courseId
    };

    this.courseExamReporter.report(dto).subscribe();
  }
}
