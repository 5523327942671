<header class="flex gap-4">
  <date-range-input
    [(range)]="range"
    [disabled]="dateRangeDisabled()"
    [max]="max()"
    [presets]="presets()"
    class="w-fit"
  ></date-range-input>

  @if (isPackageUpgradeRecommended()) {
    <div>
      <button
        wvButton
        materialIcon="diamond"
        [needsPackageUpgrade]="true"
        class="mt-1"
      >
        {{ 'common.upgrade' | transloco }}
      </button>
    </div>
  }
</header>

<div class="grid gap-8">
  <div class="cards">
    <awareness-center-statistic-card
      [heading]="'phishingSimulations.statistic.company.generalHintsHeading' | transloco"
      [text]="''"
    >
      <p
        class="p-0 self-start"
        statistic
      >
        {{
          'phishingSimulations.statistic.company.generalHintsText'
            | transloco: { url: environment.resources.helpAdminConfiguration }
            | renderHTML
        }}
      </p>
      <ac-help-button info>
        <p class="p-0">
          {{ 'phishingSimulations.statistic.company.generalHintsInfo' | transloco }}
        </p>
      </ac-help-button>
    </awareness-center-statistic-card>

    <awareness-center-statistic-card
      [heading]="'phishingSimulations.statistic.company.betterSensitizationHeading' | transloco"
      [text]="''"
    >
      <p
        class="p-0 self-start"
        statistic
      >
        {{
          'phishingSimulations.statistic.company.betterSensitizationText'
            | transloco: { url: environment.urls.packages }
            | renderHTML
        }}
      </p>
      <ac-help-button info>
        <p class="p-0">
          {{ 'phishingSimulations.statistic.company.betterSensitizationInfo' | transloco }}
        </p>
      </ac-help-button>
    </awareness-center-statistic-card>

    <awareness-center-statistic-card
      [heading]="'phishingSimulations.statistic.company.trainingHeading' | transloco"
      [text]="''"
    >
      <p
        class="p-0 self-start"
        statistic
      >
        {{
          'phishingSimulations.statistic.company.trainingText'
            | transloco: { phone: environment.contact.phone }
            | renderHTML
        }}
      </p>
      <ac-help-button info>
        <p class="p-0">
          {{ 'phishingSimulations.statistic.company.trainingInfo' | transloco }}
        </p>
      </ac-help-button>
    </awareness-center-statistic-card>
  </div>

  <mat-divider></mat-divider>

  @if (phishingMailOptions().isLoading) {
    <mat-spinner></mat-spinner>
  }

  @if (phishingMailOptions().data; as options) {
    @if (options.availability.isAvailable) {
      @if (phishingMailsCompanyStatistic().isLoading) {
        <mat-spinner></mat-spinner>
      }
      @if (phishingMailsCompanyStatistic().data; as phishingMailsCompanyStatistic) {
        @if (phishingMailsCompanyStatistic.phishingMailsSent === 0) {
          <p class="p-4 bg-secondary text-on-secondary max-w-max">
            {{ 'phishingSimulations.statistic.noData' | transloco }}
          </p>
        } @else {
          <div class="cards">
            <awareness-center-statistic-card
              [heading]="'phishingSimulations.statistic.company.totalClicksHeading' | transloco"
              [text]="
                'phishingSimulations.statistic.company.totalClicksText'
                  | transloco
                    : {
                        sentCount: phishingMailsCompanyStatistic.phishingMailsSent,
                        clickCount: phishingMailsCompanyStatistic.phishingMailsClicked
                      }
              "
            >
              <ac-help-button info>
                <p class="p-0">
                  {{
                    'phishingSimulations.statistic.company.totalClicksInfo'
                      | transloco: { url: environment.urls.packages }
                      | renderHTML
                  }}
                </p>
              </ac-help-button>
              <app-phishing-mails-total-clicks-statistic
                statistic
                [clickCount]="phishingMailsCompanyStatistic.phishingMailsClicked"
                [sentCount]="phishingMailsCompanyStatistic.phishingMailsSent"
              ></app-phishing-mails-total-clicks-statistic>
            </awareness-center-statistic-card>
            <awareness-center-statistic-card
              [heading]="'phishingSimulations.statistic.company.clickRateHeading' | transloco"
              [text]="'phishingSimulations.statistic.company.clickRateText' | transloco"
            >
              <ac-help-button info>
                <p class="p-0">
                  {{ 'phishingSimulations.statistic.company.clickRateInfo' | transloco }}
                </p>
              </ac-help-button>
              <awareness-center-percentage-animation
                statistic
                [percentage]="phishingMailsCompanyStatistic.phishingMailsClickRate"
              ></awareness-center-percentage-animation>
            </awareness-center-statistic-card>

            <awareness-center-statistic-card
              [heading]="'phishingSimulations.statistic.company.companyRankingHeading' | transloco"
              [text]="
                'phishingSimulations.statistic.company.companyRankingText'
                  | transloco
                    : {
                        minClickRateInApplication:
                          phishingMailsCompanyStatistic.minClickRateInApplication | percent: '1.0-1' : 'de-DE',
                        maxClickRateInApplication:
                          phishingMailsCompanyStatistic.maxClickRateInApplication | percent: '1.0-1' : 'de-DE',
                        companyRank: phishingMailsCompanyStatistic.companyRank
                      }
              "
            >
              <ac-help-button info>
                <p class="p-0">
                  {{ 'phishingSimulations.statistic.company.companyRankingInfo' | transloco }}
                </p>
              </ac-help-button>
              <app-phishing-mails-statistic-company-ranking
                statistic
                [companyRanking]="phishingMailsCompanyStatistic.companyRank"
              ></app-phishing-mails-statistic-company-ranking>
            </awareness-center-statistic-card>

            <awareness-center-statistic-card
              [heading]="'phishingSimulations.statistic.company.departmentsHeading' | transloco"
              [text]="'phishingSimulations.statistic.company.departmentsText' | transloco"
              class="md:col-span-2 col-start-1 row-start-2"
            >
              <ac-help-button info>
                <p class="p-0">
                  {{ 'phishingSimulations.statistic.company.departmentsInfo' | transloco }}
                </p>
              </ac-help-button>
              <app-phishing-mails-statistic-user-departments-chart
                statistic
                [phishingPerDepartment]="phishingMailsCompanyStatistic.phishingPerDepartment"
              ></app-phishing-mails-statistic-user-departments-chart>
            </awareness-center-statistic-card>

            <awareness-center-statistic-card
              [heading]="'phishingSimulations.statistic.company.clickComparisonHeading' | transloco"
              [text]="'phishingSimulations.statistic.company.clickComparisonText' | transloco"
              class="row-start-2"
            >
              <ac-help-button info>
                <p class="p-0">
                  {{ 'phishingSimulations.statistic.company.clickComparisonInfo' | transloco }}
                </p>
              </ac-help-button>
              <app-phishing-mails-statistic-company-click-comparison
                statistic
                [clickComparisons]="phishingMailsCompanyStatistic.clickComparisons"
              ></app-phishing-mails-statistic-company-click-comparison>
            </awareness-center-statistic-card>
          </div>
        }
      }
    } @else {
      <ng-container *transloco="let t">
        <p class="p-4 bg-secondary text-on-secondary max-w-max">
          {{ options.availability.reasonForUnavailability | renderHTML }}
        </p>
      </ng-container>
    }
  }
</div>
