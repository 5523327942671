import { CompanyRegistrationRequestAccessFormComponent } from './company-registration-request-access-form/company-registration-request-access-form.component';
import { NoAccessDialogComponent } from './no-access-dialog/no-access-dialog.component';
import { ColibriModule, RecaptchaConsentComponent } from '@ac/colibri';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { LayoutsModule } from '../layouts/layouts.module';
import { NavigationModule } from '../navigation/navigation.module';
import { TranslateModule } from '../translate.module';
import { AccountantRegistrationFormComponent } from './accountant-registration-form/accountant-registration-form.component';
import { AccountantRegistrationComponent } from './accountant-registration/accountant-registration.component';
import { CompanyRegistrationDetailsFormComponent } from './company-registration-details-form/company-registration-details-form.component';
import { CompanyRegistrationDetailsComponent } from './company-registration-details/company-registration-details.component';
import { CompanyRegistrationComponent } from './company-registration/company-registration.component';
import { IdentityRoutingModule } from './identity-routing.module';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { PasswordPolicyDialogComponent } from './password-policy-dialog/password-policy-dialog.component';
import { ResetPasswordRequestComponent } from './reset-password-request/reset-password-request.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SelfDeleteUserComponent } from './self-delete-user/self-delete-user.component';
import { UserEditDepartmentFormComponent } from './user-edit-department-form/user-edit-department-form.component';
import { UserEditEmailFormComponent } from './user-edit-email-form/user-edit-email-form.component';
import { UserEditEmailLandingPageComponent } from './user-edit-email-landing-page/user-edit-email-landing-page.component';
import { UserEditFirstNameFormComponent } from './user-edit-first-name-form/user-edit-first-name-form.component';
import { UserEditGenderFormComponent } from './user-edit-gender-form/user-edit-gender-form.component';
import { UserEditLastNameFormComponent } from './user-edit-last-name-form/user-edit-last-name-form.component';
import { UserEditPasswordFormComponent } from './user-edit-password-form/user-edit-password-form.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserInvitationDialogComponent } from './user-invitation-dialog/user-invitation-dialog.component';
import { UserInvitationFileFormComponent } from './user-invitation-file-form/user-invitation-file-form.component';
import { UserInvitationListFormComponent } from './user-invitation-list-form/user-invitation-list-form.component';
import { UserInvitationSelfDeleteLandingPageComponent } from './user-invitation-self-delete-landing-page/user-invitation-self-delete-landing-page.component';
import { UserLoginFormComponent } from './user-login-form/user-login-form.component';
import { UserManagementOverviewComponent } from './user-management-overview/user-management-overview.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserRegistrationFormComponent } from './user-registration-form/user-registration-form.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { MatButtonModule } from '@angular/material/button';
import { CompanyEditComponent } from './company-edit/company-edit.component';
import { CompanyEditInformationFormComponent } from './company-edit/company-edit-information-form/company-edit-information-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MaterialFileInputModule } from 'ngx-material-file-input';

export const GERMAN_DATEPICKER_LOCALE = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  declarations: [
    LoginComponent,
    UserLoginFormComponent,
    UserRegistrationComponent,
    UserRegistrationFormComponent,
    CompanyRegistrationRequestAccessFormComponent,
    LogoutComponent,
    CompanyRegistrationDetailsComponent,
    CompanyRegistrationDetailsFormComponent,
    UserManagementComponent,
    ResetPasswordComponent,
    ResetPasswordRequestComponent,
    CompanyRegistrationComponent,
    AccountantRegistrationComponent,
    AccountantRegistrationFormComponent,
    NoAccessDialogComponent,
    UserEditComponent,
    UserEditLastNameFormComponent,
    UserEditPasswordFormComponent,
    UserEditEmailFormComponent,
    UserEditFirstNameFormComponent,
    UserEditGenderFormComponent,
    UserEditDepartmentFormComponent,
    UserEditEmailLandingPageComponent,
    UserInvitationListFormComponent,
    UserInvitationFileFormComponent,
    UserInvitationDialogComponent,
    UserInvitationFileFormComponent,
    UserInvitationListFormComponent,
    UserManagementOverviewComponent,
    PasswordPolicyDialogComponent,
    SelfDeleteUserComponent,
    UserInvitationSelfDeleteLandingPageComponent,
    CompanyEditComponent,
    CompanyEditInformationFormComponent
  ],
  imports: [
    CommonModule,
    IdentityRoutingModule,
    MatButtonModule,
    MatDatepickerModule,
    MatMomentDateModule,
    ReactiveFormsModule,
    MatIconModule,
    ColibriModule,
    MatSortModule,
    MatExpansionModule,
    MatStepperModule,
    NavigationModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    LayoutsModule,
    TranslateModule,
    TranslateModule,
    MatButtonModule,
    RecaptchaConsentComponent,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MaterialFileInputModule
  ],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: GERMAN_DATEPICKER_LOCALE }, DatePipe],
  exports: [
    LoginComponent,
    UserLoginFormComponent,
    UserRegistrationComponent,
    UserRegistrationFormComponent,
    CompanyRegistrationRequestAccessFormComponent,
    CompanyRegistrationDetailsComponent,
    CompanyRegistrationDetailsFormComponent,
    UserManagementComponent,
    ResetPasswordComponent,
    ResetPasswordRequestComponent,
    CompanyRegistrationComponent,
    AccountantRegistrationComponent,
    AccountantRegistrationFormComponent,
    NoAccessDialogComponent,
    UserEditComponent,
    UserEditLastNameFormComponent,
    UserEditPasswordFormComponent,
    UserEditEmailFormComponent,
    UserEditFirstNameFormComponent,
    UserEditGenderFormComponent,
    UserEditDepartmentFormComponent,
    UserEditEmailLandingPageComponent,
    UserInvitationListFormComponent,
    UserInvitationFileFormComponent,
    UserInvitationDialogComponent,
    UserManagementOverviewComponent,
    PasswordPolicyDialogComponent,
    SelfDeleteUserComponent,
    UserInvitationSelfDeleteLandingPageComponent
  ]
})
export class IdentityModule {}
