import { ColibriModule } from '@ac/colibri';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@app/translate.module';
import { HarmfulFilesRemovalComponent } from './components/harmful-files-removal/harmful-files-removal.component';
import { LeftOrRightChoiceComponent } from './components/left-or-right-choice/left-or-right-choice.component';
import { MultipleChoiceComponent } from './components/multiple-choice/multiple-choice.component';
import { PasswordMemorizationComponent } from './components/password-memorization/password-memorization.component';
import { QuestionAndAnswerComponent } from './components/sentence-memorization/question-and-answer.component';
import { SingleChoiceComponent } from './components/single-choice/single-choice.component';
import { TaskHeaderComponent } from './components/task-header.component';
import { TemporaryEmailComponent } from './components/temporary-email/temporary-email.component';
import { ExamSummaryComponent } from './exam-summary.component';
import { TASK_COMPONENT_REGISTRY } from './exam-task-registry-token';
import { ExamComponent } from './exam.component';
import { TaskSummaryComponent } from './task-summary.component';
import { SafePipe } from './value-guards/safe.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ColibriModule,
    DragDropModule,
    MatIconModule,
    TranslateModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    MatRadioModule,
    MatTooltipModule
  ],
  declarations: [
    ExamComponent,
    ExamSummaryComponent,
    HarmfulFilesRemovalComponent,
    LeftOrRightChoiceComponent,
    MultipleChoiceComponent,
    PasswordMemorizationComponent,
    SingleChoiceComponent,
    SafePipe,
    QuestionAndAnswerComponent,
    TaskSummaryComponent,
    TaskHeaderComponent,
    TemporaryEmailComponent
  ],
  exports: [ExamComponent],
  providers: [
    {
      provide: TASK_COMPONENT_REGISTRY,
      useValue: [
        {
          type: 'left-or-right-choice',
          component: LeftOrRightChoiceComponent
        },
        {
          type: 'multiple-choice',
          component: MultipleChoiceComponent
        },
        {
          type: 'password-memorization',
          component: PasswordMemorizationComponent
        },
        {
          type: 'question-and-answer',
          component: QuestionAndAnswerComponent
        },
        {
          type: 'single-choice',
          component: SingleChoiceComponent
        },
        {
          type: 'temporary-email',
          component: TemporaryEmailComponent
        },
        {
          type: 'harmful-files-removal',
          component: HarmfulFilesRemovalComponent
        }
      ]
    }
  ]
})
export class ExamModule {}
