import {Component, Input} from '@angular/core';

@Component({
  selector: 'wv-card',
  template: `
    <mat-card [class]="'mat-elevation-z4 h-full min-w-fit'">
      <div class="flex flex-col h-full">
      @if (overline || title || image) {
        <mat-card-header class="header">
          <div class="heading">
            @if (overline) {
              <h4 class="title">
                {{ overline }}
              </h4>
            }
            @if (title) {
              <div class="flex justify-between items-center mb-2">
                <h3 class="p-0">{{ title }}</h3>
                <ng-content select="[action]"></ng-content>
              </div>
            }
          </div>
          @if (image) {
            <img
                class="image"
                [src]="image.src"
                [attr.alt]="image.alt"
                height="80"
            />
          }
        </mat-card-header>
      }
      <mat-card-content class="!mb-0 h-full">
        <ng-content></ng-content>
      </mat-card-content>
      </div>
    </mat-card>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .header {
        display: flex;
        gap: 1rem;
      }

      .heading {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      .title {
        color: var(--secondary);
        margin-bottom: 0.4rem;
      }

      .image {
        align-self: flex-end;
      }
    `
  ]
})
export class CardComponent {
  @Input() overline = '';
  @Input() title = '';
  @Input() image?: { src: string; alt: string; height: number };
}
