<img
  [src]="environment.assets.logo"
  align="left"
  alt="co-IT Logo"
/>

<div class="player">
  <ng-content></ng-content>
</div>

<div class="text">
  <h3 *ngIf="subtitle">{{ subtitle }}</h3>
  <h2 *ngIf="title">{{ title }}</h2>
  <p *ngIf="description">{{ description }}</p>
</div>
