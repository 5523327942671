<button
  (click)="toggleMenu()"
  tabindex="0"
  class="feedback-button"
  appearance="compact-secondary"
  id="feedback-button"
  wvButton
  materialIcon="contact_support"
>
  <span>Kontakt</span>
</button>

<div
  class="feedback-container mat-elevation-z8"
  id="feedback-container"
  [class.open]="isOpen()"
>
  <div class="title">
    <span class="title-text">Nachricht senden</span>
    <button
      (click)="toggleMenu()"
      mat-icon-button
      aria-label="close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="main">
    <div class="header">
      <span class="bold">Absender</span>
      <span>{{ identityService.getUserName() }}</span>
      <span class="bold">E-Mail</span>
      <span>{{ identityService.getUserEmail() }}</span>
    </div>
    <div class="form">
      <form
        (ngSubmit)="onSubmit(feedbackForm, $event)"
        [formGroup]="feedbackForm"
      >
        <div class="input-info-wrapper">
          <mat-form-field
            appearance="outline"
            class="full-width"
          >
            <mat-label>Betreff</mat-label>
            <mat-select formControlName="subject">
              <mat-option
                *ngFor="let subject of subjects"
                [value]="subject"
              >
                {{ subject }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="feedbackForm.get('subject').errors?.required">Pflichtfeld</mat-error>
          </mat-form-field>
          <app-info-box
            [description]="'info-box.feedbackSubject.description' | transloco"
            [gdpr]="'info-box.common.contactGdpr' | transloco"
          ></app-info-box>
        </div>
        <div class="textarea-info-wrapper message-field">
          <mat-form-field
            appearance="outline"
            class="full-width"
          >
            <textarea
              formControlName="message"
              matInput
              aria-label="message"
            ></textarea>
            <mat-error *ngIf="feedbackForm.get('message').errors?.required">Pflichtfeld</mat-error>
            <mat-error *ngIf="feedbackForm.get('message').errors?.maxLength">maximal 1000 Zeichen</mat-error>
          </mat-form-field>
          <app-info-box
            [description]="'info-box.feedbackMessage.description' | transloco"
            [gdpr]="'info-box.common.contactGdpr' | transloco"
          ></app-info-box>
        </div>
        <button
          [disabled]="feedbackForm.invalid"
          appearance="secondary"
          class="submit-button"
          wvButton
        >
          Senden
        </button>
      </form>
    </div>
  </div>
</div>
