import { CompanyListDto } from '@ac/models';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IdentityService } from '../../identity/identity.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss']
})
export class CompanyManagementComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  roles: string[];
  dataSource: MatTableDataSource<CompanyListDto> = new MatTableDataSource<CompanyListDto>();
  displayedColumns = [
    'name',
    'accountantName',
    'employeeCount',
    'officeEmployeeCount',
    'businessSector',
    'numberOfUsers',
    'numberOfPendingRegistrations',
    'createdAt'
  ];

  constructor(private identityService: IdentityService) {}

  ngOnInit(): void {
    this.identityService.getCompanies().subscribe(companies => (this.dataSource.data = companies));
  }

  getCompanyCount(): number {
    return this.dataSource.data.length;
  }

  getTotalUserCount(): number {
    return this.dataSource.data.reduce((sum, current) => sum + current.numberOfUsers, 0);
  }

  getTotalEmployeeCount(): number {
    return this.dataSource.data.reduce((sum, current) => sum + current.employeeCount, 0);
  }

  getTotalOfficeEmployeeCount(): number {
    return this.dataSource.data.reduce((sum, current) => sum + current.officeEmployeeCount, 0);
  }

  getTotalPendingRegistrationsCount(): number {
    return this.dataSource.data.reduce((sum, current) => sum + current.numberOfPendingRegistrations, 0);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}
