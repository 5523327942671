import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IdentityService } from '../../identity/identity.service';
import { CustomValidators } from '../../identity/custom.validator';
import { CreateWhitelistedAccessDto } from '@ac/models';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-whitelist-access-dialog',
  templateUrl: './whitelist-access-dialog.component.html',
  styleUrls: ['./whitelist-access-dialog.component.scss']
})
export class WhitelistAccessDialogComponent {
  inviteAgencyForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private identityService: IdentityService,
    public dialogRef: MatDialogRef<WhitelistAccessDialogComponent>
  ) {
    this.inviteAgencyForm = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidators.email, CustomValidators.username]]
    });
  }

  onSubmit(inviteAgencyForm: UntypedFormGroup, event: Event): void {
    event.preventDefault();

    if (inviteAgencyForm.invalid) {
      return;
    }

    const email = inviteAgencyForm.get('email').value as string;

    const dto: CreateWhitelistedAccessDto = {
      email
    };

    this.identityService.whitelistAccess(dto).subscribe();
  }
}
