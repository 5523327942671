<ac-task-header [configuration]="configuration"></ac-task-header>

<div class="lists-wrapper">
  <div>
    <h3>{{ configuration.headings.left }}</h3>
    <div
      #optionsLeftList="cdkDropList"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListConnectedTo]="[optionsRightList, possibilitiesList]"
      [cdkDropListData]="optionsLeft"
      cdkDropList
      class="list-box"
      data-testid="Left-Drop-Field"
    >
      <div
        *ngFor="let item of optionsLeft"
        cdkDrag
        class="list-item"
        data-test="single-drop-item-left"
        tabindex="0"
        (keydown)="moveToList($event, item)"
      >
        <span class="badge">{{ item.position }}</span>
        <span class="item-label">{{ item.label }}</span>
      </div>
    </div>
  </div>
  <div>
    <h3>{{ configuration.headings.center }}</h3>
    <div
      #possibilitiesList="cdkDropList"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListConnectedTo]="[optionsLeftList, optionsRightList]"
      [cdkDropListData]="options"
      cdkDropList
      class="list-box"
    >
      <div
        *ngFor="let item of options"
        cdkDrag
        class="list-item"
        data-testid="Drag-and-drop-Item"
        tabindex="0"
        (keydown)="moveToList($event, item)"
      >
        <span class="badge">{{ item.position }}</span>
        <span class="item-label">{{ item.label }}</span>
      </div>
    </div>
  </div>

  <div>
    <h3>{{ configuration.headings.right }}</h3>
    <div
      #optionsRightList="cdkDropList"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListConnectedTo]="[optionsLeftList, possibilitiesList]"
      [cdkDropListData]="optionsRight"
      cdkDropList
      class="list-box"
      data-testid="Right-Drop-Field"
    >
      <div
        *ngFor="let item of optionsRight"
        cdkDrag
        class="list-item"
        data-testid="drag-item-right"
        tabindex="0"
        (keydown)="moveToList($event, item)"
      >
        <span class="badge">{{ item.position }}</span>
        <span class="item-label">{{ item.label }}</span>
      </div>
    </div>
  </div>
</div>

<div class="commands">
  <button
    (click)="submitAnswer()"
    [disabled]="formSubmitted || options.length !== 0"
    appearance="secondary"
    wvButton
    data-test="button-submit-solution"
    data-testid="button-submit-solution"
  >
    {{ 'exam.submitAnswerButton' | transloco }}
  </button>
</div>
