<ac-box-centered>
  <mat-horizontal-stepper
    #stepper
    [disableRipple]="true"
    [selectedIndex]="1"
  >
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>
    <mat-step
      [completed]="true"
      [editable]="false"
      [label]="'registration.stepper.step1.title' | transloco"
      cdkFocusInitial
    ></mat-step>
    <mat-step
      [completed]="false"
      [label]="'registration.stepper.step2.title' | transloco"
      cdkFocusInitial
      state="edit"
    >
      <app-company-registration-details
        (companyDetails)="updateCompanyDetails($event)"
      ></app-company-registration-details>
    </mat-step>
    <mat-step
      [completed]="false"
      [label]="'registration.stepper.step3.title' | transloco"
    >
      <app-accountant-registration
        (accountantDetails)="accountantDetails = $event; registerCompany()"
        (back)="previousStep()"
      ></app-accountant-registration>
    </mat-step>
  </mat-horizontal-stepper>
</ac-box-centered>
