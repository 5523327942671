<form
  (ngSubmit)="onSubmitFile(bulkInviteUserFileForm, $event)"
  [formGroup]="bulkInviteUserFileForm"
>
  <div class="input-info-wrapper">
    <mat-form-field
      appearance="outline"
      class="full-width"
      data-testid="input-wrapper"
    >
      <mat-label>{{ 'form.label.selectFile' | transloco }}</mat-label>
      <mat-icon matSuffix>file_upload</mat-icon>
      <ngx-mat-file-input
        data-testid="file-input"
        (change)="setFile($event)"
        [accept]="allowedFileTypes"
        formControlName="file"
      ></ngx-mat-file-input>
      <mat-error *ngIf="bulkInviteUserFileForm.get('file').errors?.required">
        {{ 'form.error.required' | transloco }}
      </mat-error>
      <mat-error *ngIf="bulkInviteUserFileForm.get('file').errors?.maxContentSize">
        {{ 'form.error.maxFileSize' | transloco: { maxFileSize: '250 KB' } }}
      </mat-error>
      <mat-error *ngIf="bulkInviteUserFileForm.get('file').errors?.wrongFileType">
        {{ 'form.error.invalidFileType' | transloco }}
      </mat-error>
    </mat-form-field>
    <app-info-box
      [description]="
        ('info-box.userInvitationFileUpload.description' | transloco) +
        '<br>' +
        ('info-box.common.invitationDescription' | transloco)
      "
      [gdpr]="'info-box.common.invitationGdpr' | transloco"
    ></app-info-box>
  </div>
  <button
    [disabled]="bulkInviteUserFileForm.invalid"
    appearance="secondary"
    wvButton
    data-testid="button-upload-file"
  >
    {{ 'userManagement.invitation.file.submitButton' | transloco }}
  </button>
</form>
