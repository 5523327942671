import { SelectOptionsService } from '@ac/colibri';
import { UpdateLastNameDto } from '@ac/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-edit-last-name-form',
  templateUrl: './user-edit-last-name-form.component.html',
  styleUrls: ['./user-edit-last-name-form.component.scss']
})
export class UserEditLastNameFormComponent implements OnInit {
  @Output() submitForm = new EventEmitter<UpdateLastNameDto>();
  @Input() lastName: string;
  userEditLastNameForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.userEditLastNameForm = this.formBuilder.group({
      lastName: ['', [Validators.required]]
    });

    this.userEditLastNameForm.get('lastName').setValue(this.lastName);
  }

  onSubmit(userData: UntypedFormGroup, event: Event): void {
    event.preventDefault();

    if (userData.invalid) {
      return;
    }

    const dto: UpdateLastNameDto = {
      lastName: userData.get('lastName').value
    };

    this.submitForm.emit(dto);
  }
}
