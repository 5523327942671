import { Component, inject, viewChild } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { ColibriModule } from '@ac/colibri';
import { ReadCompanySubscriptionDto } from '../../company-subscriptions/subscriptions.service';
import { JsonPipe, NgIf } from '@angular/common';
import { TranslocoDirective, TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { UrlValidatorDirective } from './url-validator.directive';
import { FormsModule, NgForm } from '@angular/forms';
import { ChangeAwarenessPageClient } from './change-awareness-page-client.service';
import { ContactWindowState } from '../../../feedback';
import { environment } from '@env';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cl-change-awareness-page-dialog',
  standalone: true,
  imports: [
    MatInputModule,
    ColibriModule,
    TranslocoPipe,
    UrlValidatorDirective,
    NgIf,
    FormsModule,
    JsonPipe,
    TranslocoDirective,
    MatDialogModule
  ],
  providers: [ChangeAwarenessPageClient],
  template: `
    <ng-container *transloco="let t">
      <h3
        mat-dialog-title
        class="!m-0 !font-bold !text-xl"
      >
        {{ t('subscription.awareness-page-redirect-dialog.title') }}
      </h3>
      <div mat-dialog-content>
        <p>
          {{ t('subscription.awareness-page-redirect-dialog.description') }}
        </p>

        <p>
          {{ t('subscription.awareness-page-redirect-dialog.hint') }}
          <a
            [href]="defaultAwarenessPageRedirectUrl"
            class="text-[--primary]"
            target="_blank"
          >
            {{ t('subscription.awareness-page-redirect-dialog.default-redirect-text') }}
          </a>
          {{ t('subscription.awareness-page-redirect-dialog.hint-ending') }}
        </p>

        <form
          #form="ngForm"
          class="mt-4"
        >
          <h3 class="text-xl">{{ t('subscription.awareness-page-redirect-dialog.form-title') }}</h3>

          <div class="input-info-wrapper">
            <mat-form-field
              appearance="outline"
              class="w-full"
            >
              @if (isNotEligibleForChangingAwarenessPage) {
                <mat-label>
                  {{ t('subscription.awareness-page-redirect-dialog.custom-redirect-not-available') }}
                </mat-label>
              } @else {
                <mat-label>
                  {{ t('subscription.awareness-page-redirect-dialog.custom-redirect-url-example') }}
                </mat-label>
              }

              <input
                [disabled]="isNotEligibleForChangingAwarenessPage"
                [(ngModel)]="awarenessPageRedirectUrl"
                name="awarenessPageUrl"
                #awarenessPageUrlInput="ngModel"
                clUrl
                matInput
                [placeholder]="t('subscription.awareness-page-redirect-dialog.custom-redirect-url-example')"
              />
              @if (awarenessPageUrlInput.errors) {
                <mat-error>{{ 'form.error.url' | transloco }}</mat-error>
              }
            </mat-form-field>
            <app-info-box
              [description]="t('info-box.common.companyDescription')"
              [gdpr]="t('info-box.common.companyGdpr')"
            ></app-info-box>
          </div>
        </form>

        <footer class="flex gap-4">
          <button
            wvButton
            appearance="outline-secondary"
            (click)="close()"
          >
            {{ 'common.cancel' | transloco }}
          </button>

          @if (isNotEligibleForChangingAwarenessPage) {
            <button
              wvButton
              (click)="forwardToContactFormular()"
              materialIcon="diamond"
            >
              {{ t('common.upgrade') }}
            </button>
          } @else {
            @if (customerSubscription.phishing.redirectUrl) {
              <button
                wvButton
                appearance="outline-warning"
                (click)="clearEnteredAwarenessPage()"
              >
                {{ t('common.remove') }}
              </button>
            }
            <button
              wvButton
              (click)="saveEnteredAwarenessPage()"
            >
              {{ t('common.save') }}
            </button>
          }
        </footer>
      </div>
    </ng-container>
  `
})
export class ChangeAwarenessPageDialogComponent {
  protected form = viewChild(NgForm);
  protected customerSubscription = inject<ReadCompanySubscriptionDto>(MAT_DIALOG_DATA);
  protected defaultAwarenessPageRedirectUrl = environment.subscriptions.defaultAwarenessPageRedirectUrl;
  protected awarenessPageRedirectUrl = this.customerSubscription.phishing.redirectUrl || '';
  #dialog = inject(MatDialogRef<ChangeAwarenessPageDialogComponent>);
  #transloco = inject(TranslocoService);
  #contactWindow = inject(ContactWindowState);
  #changeAwarenessPage = inject(ChangeAwarenessPageClient).changeAwarenessPage();

  get isNotEligibleForChangingAwarenessPage() {
    return !this.customerSubscription.phishing.allowCustomRedirectUrl;
  }

  protected async saveEnteredAwarenessPage() {
    if (this.form().invalid) return;

    await this.#changeAwarenessPage.mutateAsync({
      subscriptionId: this.customerSubscription.id,
      phishingRedirectUrl: this.awarenessPageRedirectUrl
    });

    this.close();
  }

  protected async clearEnteredAwarenessPage() {
    await this.#changeAwarenessPage.mutateAsync({
      subscriptionId: this.customerSubscription.id,
      phishingRedirectUrl: ''
    });

    this.close();
  }

  protected close() {
    this.#dialog.close();
  }

  protected forwardToContactFormular() {
    this.#contactWindow.setForm({
      subject: this.#transloco.translate('subscription.awareness-page-redirect-dialog.package-upgrade-form-subject'),
      message: this.#transloco.translate('subscription.awareness-page-redirect-dialog.package-upgrade-form-message')
    });

    this.#dialog.close();
    this.#contactWindow.open();
  }
}
