import { ChangeDetectionStrategy, Component, effect, Signal, signal, ViewChild } from '@angular/core';
import { IdentityService } from '../../identity/identity.service';
import { UrlBuilder } from '../../url-builder.service';
import { environment } from '@env';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { filter, map, tap } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent {
  isUserMenuOpen = false;
  shouldUseMobileMenu$$: Signal<boolean>;
  isMobileMenuOpen$$ = signal(false);
  protected readonly environment = environment;

  constructor(
    public urlBuilder: UrlBuilder,
    public identityService: IdentityService,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {
    this.shouldUseMobileMenu$$ = toSignal(
      this.breakpointObserver.observe(['(max-width: 1500px)']).pipe(
        takeUntilDestroyed(),
        map(state => state.matches)
      )
    );

    this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter(x => x instanceof NavigationStart),
        tap(() => this.isMobileMenuOpen$$.set(false))
      )
      .subscribe();

    effect(
      () => {
        if (!this.shouldUseMobileMenu$$()) {
          this.isMobileMenuOpen$$.set(false);
        }
      },
      { allowSignalWrites: true }
    );
  }

  toggleMobileMenu() {
    this.isMobileMenuOpen$$.update(isOpen => !isOpen);
  }
}
