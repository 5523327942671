import { CustomValidator, PasswordValidationErrors, PasswordValidators, SelectOptionsService } from '@ac/colibri';
import { AccountantRegistrationDetailsDto, PasswordPolicyDto } from '@ac/models';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PasswordPolicyDialogComponent } from '../password-policy-dialog/password-policy-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-accountant-registration-form',
  templateUrl: './accountant-registration-form.component.html',
  styleUrls: ['./accountant-registration-form.component.scss']
})
export class AccountantRegistrationFormComponent implements OnInit {
  @ViewChild(MatExpansionPanel) passwordCheckPanel: MatExpansionPanel;

  @Input() passwordPolicy: PasswordPolicyDto;

  @Output() submitForm = new EventEmitter<AccountantRegistrationDetailsDto>();
  @Output() back = new EventEmitter<void>();

  accountantRegistrationForm: UntypedFormGroup;
  environment = environment;
  passwordHide = true;
  passwordValidationErrors$: Observable<PasswordValidationErrors>;

  isPasswordSpecificationNotSatisfied = true;

  options = this.selectOptionsService.departments();

  filteredOptions: Observable<string[]>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private selectOptionsService: SelectOptionsService
  ) {}

  ngOnInit(): void {
    this.accountantRegistrationForm = this.formBuilder.group(
      {
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        password: ['', [Validators.required, PasswordValidators.policy(this.passwordPolicy)]],
        password2: ['', [Validators.required, PasswordValidators.policy(this.passwordPolicy)]],
        gender: ['', [Validators.required]],
        department: ['', [Validators.required, CustomValidator.requireMatch(this.options)]],
        privacy: ['', [Validators.required]],
        termsOfUse: ['', [Validators.required]]
      },
      { validators: PasswordValidators.equalPasswords }
    );

    this.filteredOptions = this.accountantRegistrationForm.get('department').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.passwordValidationErrors$ = this.accountantRegistrationForm.get('password').valueChanges.pipe(
      map(() => Object.keys(this.accountantRegistrationForm.get('password').errors || []) as PasswordValidationErrors),
      tap(errors => {
        /**
         *
         * Somehow the control error of password is not populated to the form.
         * The form indicates itself as valid, although password contains errors.
         *
         * Until we found the root cause for this behaviour we have to use additional
         * state to disable the registration button.
         */
        if (errors.length) {
          this.isPasswordSpecificationNotSatisfied = true;
        } else {
          this.isPasswordSpecificationNotSatisfied = false;
        }
      })
    );

    console.log('here here >>>>', this.accountantRegistrationForm);
  }

  onSubmit(accountantData: UntypedFormGroup, event: Event): void {
    event.preventDefault();

    if (accountantData.invalid) {
      return;
    }

    const dto: AccountantRegistrationDetailsDto = {
      firstName: accountantData.get('firstName').value,
      lastName: accountantData.get('lastName').value,
      password: accountantData.get('password').value,
      isFemale: accountantData.get('gender').value !== 'male',
      department: accountantData.get('department').value
    };

    this.submitForm.emit(dto);
  }

  openPasswordInfoDialog(): void {
    this.dialog.open(PasswordPolicyDialogComponent, {
      data: this.passwordPolicy
    });
  }

  openPasswordCheckPanel(): void {
    this.passwordCheckPanel?.open();
  }

  goBack() {
    this.back.emit();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
}
