import { CustomValidator, SelectOptionsService } from '@ac/colibri';
import { CompanyRegistrationDetailsDto } from '@ac/models';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-company-registration-details-form',
  templateUrl: './company-registration-details-form.component.html',
  styleUrls: ['./company-registration-details-form.component.scss']
})
export class CompanyRegistrationDetailsFormComponent implements OnInit {
  companyRegistrationForm: UntypedFormGroup;

  @Output() submitForm = new EventEmitter<CompanyRegistrationDetailsDto>();
  options = this.selectOptionsService.businessSectors();
  filteredOptions: Observable<string[]>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private selectOptionsService: SelectOptionsService
  ) {
    this.companyRegistrationForm = this.formBuilder.group({
      companyName: [
        '',
        [Validators.required, Validators.pattern(`^^[0-9a-zA-ZäöüÄÖÜß]+([0-9a-zA-ZäöüÄÖÜß\\'\\,\\.\\-\\&\\! ])*$`)]
      ],
      businessSector: ['', [Validators.required, CustomValidator.requireMatch(this.options)]],
      employeeCount: ['', [Validators.required, Validators.min(1)]],
      officeEmployeeCount: ['', [Validators.required, Validators.min(1)]]
    });
  }

  ngOnInit(): void {
    this.filteredOptions = this.companyRegistrationForm.get('businessSector').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  emitRegistrationData(companyRegistrationForm: UntypedFormGroup, event: Event): void {
    event.preventDefault();

    if (companyRegistrationForm.invalid) {
      return;
    }

    const dto: CompanyRegistrationDetailsDto = {
      companyName: this.companyRegistrationForm.get('companyName').value,
      businessSector: this.companyRegistrationForm.get('businessSector').value,
      employeeCount: this.companyRegistrationForm.get('employeeCount').value,
      officeEmployeeCount: this.companyRegistrationForm.get('officeEmployeeCount').value
    };

    this.submitForm.emit(dto);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
}
