import { AccountantRegistrationDetailsDto, CompanyRegistrationDetailsDto, RegisterCompanyDto } from '@ac/models';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UrlBuilder } from '../../url-builder.service';
import { IdentityService } from '../identity.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-company-registration',
  templateUrl: './company-registration.component.html',
  styleUrls: ['./company-registration.component.scss']
})
export class CompanyRegistrationComponent implements OnInit {
  environment = environment;
  accountantEmail: string;
  accessCode: string;
  companyDetails: CompanyRegistrationDetailsDto;
  accountantDetails: AccountantRegistrationDetailsDto;
  formBuilder = inject(UntypedFormBuilder);
  testFormGroup = this.formBuilder.group({
    firstName: ['', [Validators.required]]
  });
  testFormGroup1 = this.formBuilder.group({
    firstName: ['', [Validators.required]]
  });
  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    private identityService: IdentityService,
    private route: ActivatedRoute,
    private router: Router,
    private urlBuilder: UrlBuilder
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const accessCode = params.accessCode;

      if (!accessCode) {
        this.router.navigateByUrl(this.urlBuilder.goToLogin());
      }
      this.accessCode = accessCode;
    });
    this.route.queryParams.subscribe(params => {
      const email = params.email;

      if (!email) {
        this.router.navigateByUrl(this.urlBuilder.goToLogin());
      }
      this.accountantEmail = email;
    });
  }

  registerCompany(): void {
    const command: RegisterCompanyDto = {
      businessSector: this.companyDetails.businessSector,
      companyName: this.companyDetails.companyName,
      department: this.accountantDetails.department,
      employeeCount: this.companyDetails.employeeCount,
      firstName: this.accountantDetails.firstName,
      isFemale: this.accountantDetails.isFemale,
      lastName: this.accountantDetails.lastName,
      officeEmployeeCount: this.companyDetails.officeEmployeeCount,
      password: this.accountantDetails.password,
      accessCode: this.accessCode,
      accountantMail: this.accountantEmail
    };

    this.identityService.registerCompany(command).subscribe(() => {
      this.stepper.selected.completed = true;
      this.router.navigateByUrl(this.urlBuilder.goToLogin());
    });
  }

  updateCompanyDetails(details: CompanyRegistrationDetailsDto): void {
    this.companyDetails = details;
  }

  previousStep(): void {
    this.stepper.selected.completed = false;
    this.stepper.previous();
  }
}
